/*! RichText component */

.RichText {
  background-color: $color-white;

  & + .RichText {
    .RichText-container {
      padding-top: 0;
    }
  }
}

.RichText-container {
  @extend %container;
  @include xy-grid;
  justify-content: center;
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(90);
}

.RichText-content {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(10);
  }
}

/*! RichText global style */
.RichText {
  h1 {
    @extend .h1-style;
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(30);
    }
  }

  h2 {
    margin-top: rem-calc(30);

    @include breakpoint(medium) {
      margin-top: rem-calc(50);
    }
  }

  @for $i from 2 through 6 {
    h#{$i} {
      @extend .h#{$i - 1}-style;
      margin-bottom: rem-calc(20);

      @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
      }
    }
  }

  p {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
    font-weight: 300;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not([class]) {
    @include link-underline($inverted: true);
    line-height: 1;
    font-weight: 700;
  }

  strong {
    font-weight: 700;
  }

  figure img {
    width: initial;
    max-width: 100%;
    height: auto;
  }

  ul,
  ol {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
    font-weight: 300;

    @include breakpoint(medium) {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(30);
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
      position: relative;
      padding-left: rem-calc(40);

      &:not(:last-child) {
        margin-bottom: rem-calc(10);
      }
    }
  }

  ul {
    li {
      &::before {
        content: '';
        position: absolute;
        top: rem-calc(10);
        right: calc(100% - #{rem-calc(22)});
        width: rem-calc(8);
        height: rem-calc(8);
        background-color: currentColor;
        border-radius: 50%;
      }
    }
  }

  ol {
    counter-reset: ordered-list-increment;

    li {
      counter-increment: ordered-list-increment;

      &::before {
        content: counter(ordered-list-increment) '.';
        position: absolute;
        top: rem-calc(0);
        right: calc(100% - #{rem-calc(25)});
        font-weight: initial;
      }
    }
  }

  blockquote {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
    text-align: center;
    font-size: rem-calc(14);
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: initial;
      margin-bottom: rem-calc(70);
    }

    p:first-child {
      padding-left: rem-calc(35);
      padding-right: rem-calc(35);
      font-size: rem-calc(18);
      font-weight: 700;
      line-height: 1.3;
      background:
        url(map-get($sprites, 'quotes-open')) top left / rem-calc(28 39) no-repeat,
        url(map-get($sprites, 'quotes-close')) bottom right / rem-calc(28 39) no-repeat;

      @include breakpoint(medium) {
        padding-left: rem-calc(50);
        padding-right: rem-calc(50);
        font-size: rem-calc(24);
        background:
          url(map-get($sprites, 'quotes-open')) top left / rem-calc(30 42) no-repeat,
          url(map-get($sprites, 'quotes-close')) bottom right / rem-calc(30 42) no-repeat;
      }
    }
  }

  .introduction {
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(30);
    font-size: rem-calc(17);
    font-weight: 400;
    line-height: 1.4;

    @include breakpoint(medium) {
      font-size: rem-calc(20);
      font-weight: 700;
    }
  }

  .important {
    font-weight: 500;
  }

  .keyNumber {
    margin-bottom: rem-calc(25);
    text-align: center;
    font-size: rem-calc(30);
    font-weight: 700;
    line-height: 1;
    color: map-get($color-brand, 'blue');

    @include breakpoint(large) {
      margin-bottom: rem-calc(45);
      font-size: rem-calc(55);
    }
  }

  .keyNumber-number {
    display: block;
    font-size: rem-calc(65);

    @include breakpoint(large) {
      font-size: rem-calc(130);
    }
  }

  .accordion {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }

  .accordion-item {
    &.is-active {
      .accordion-title {
        &::after {
          transform: rotateX(180deg);
        }
      }
    }
  }

  .accordion-title {
    display: inline-flex;
    font-weight: 300;

    &::before {
      content: '';
      display: inline-block;
      width: rem-calc(4);
      min-width: rem-calc(4);
      height: rem-calc(4);
      margin-left: rem-calc(5);
      margin-right: rem-calc(12);
      margin-top: rem-calc(11);
      background-color: currentColor;
      border-radius: rem-calc(2);
    }

    &::after {
      @include sprite('arrow-down');
      content: '';
      display: inline-block;
      align-self: center;
      width: rem-calc(18);
      min-width: rem-calc(18);
      height: rem-calc(13);
      margin-left: rem-calc(15);
      transition: transform .6s ease(out-quint);
    }
  }

  .accordion-content {
    @include accordion-content;
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);

    @include breakpoint(medium) {
      padding-left: rem-calc(35);
    }
  }

  .ez-embed-type-image {
    &.align-center {
      text-align: center;
    }

    &.align-left,
    &.align-right {
      max-width: 50%;
      margin-bottom: rem-calc(5);

      @include breakpoint(medium) {
        max-width: 33%;
      }

      figure img {
        width: 100%;
      }
    }

    &.align-left {
      float: left;
      margin-right: rem-calc(15);
    }

    &.align-right {
      float: right;
      margin-left: rem-calc(15);
    }
  }

  .ez-has-anchor {
    &::before {
      content: '';
      display: block;
      margin-top: rem-calc(-170);
      padding-top: rem-calc(170);

      @include breakpoint(large) {
        margin-top: rem-calc(-270);
        padding-top: rem-calc(270);
      }
    }
  }

  @each $color, $value in $color-brand {
    .#{$color} {
      color: map-get($color-brand, $color);
    }
  }
}
