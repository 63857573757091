/*! MST Component */

$mst-colors: (
  'orange': #FF2F2D,
  'yellow': #FFF429,
  'darkRed': #CD4F41
);

.MstBanner {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: map-get($mst-colors, 'yellow');
}

.MstBanner-container {
  @extend %container;
}

.MstBanner-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MstBanner-img {
  position: relative;
  max-width: rem-calc(100);
  margin-top: rem-calc(-20);

  @include breakpoint(medium) {
    max-width: rem-calc(130);
  }
}

.MstBanner-title {
  max-width: rem-calc(450);
  margin-top: rem-calc(-10);
  margin-bottom: rem-calc(50);
  text-align: center;

  @include breakpoint(medium) {
    margin-top: rem-calc(-15);
  }

}

.MstBanner-buttons {
  @include xy-grid;
  @include xy-gutters($negative: true, $gutters: rem-calc(30));
  justify-content: center;
}

.MstBanner-button {
  @include xy-cell(shrink, $gutters: rem-calc(30));
}

.MstBanner-button--main {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(10);
  }

  .Button {
    background-color: map-get($mst-colors, 'darkRed');
    color: $color-white;

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        background-color: darken(map-get($mst-colors, 'darkRed'), 8);
      }
    }
  }
}
