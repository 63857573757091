/* TISESlider component */

.TISESlider {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-white;

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
  }
}

.TISESlider-container {
  @extend %container;
}

.TISESlider-title {
  margin-bottom: rem-calc(50);
  text-align: center;
}

.TISESlider-slider {
  padding-bottom: rem-calc(50);

  @include breakpoint(large) {
    padding-left: rem-calc(75);
    padding-right: rem-calc(75);
  }
}

.TISESlider-sliderItemFigure {
  position: relative;
  border-radius: rem-calc(45);
  box-shadow: rem-calc(0 7 15 -5) $shadow-soft;
  overflow: hidden;

  @include breakpoint(small only) {
    height: 75vh;
    min-height: rem-calc(500);
    border-radius: rem-calc(20);
  }
}

.TISESlider-sliderItemImage {
  @include breakpoint(small only) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.TISESlider-sliderItemCaption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: rem-calc(15);
  font-size: rem-calc(13);
  background-color: $color-white;

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem-calc(15 50);
  }

  img {
    max-width: rem-calc(75);
    margin-left: rem-calc(30);

    @include breakpoint(small only) {
      display: block;
      margin: rem-calc(10) auto 0;
    }
  }
}

.TISESlider-sliderPagination {
  width: 100%;

  @include breakpoint(medium) {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    margin-left: rem-calc(10) !important;
    margin-right: rem-calc(10) !important;
    background-color: $color-divider;
    opacity: 1;
    transition:
      background-color .4s ease(out-quint),
      transform .4s ease(out-quint);
  }

  .swiper-pagination-bullet-active {
    background-color: map-get($color-brand, 'blue');
    transform: scale(1.25);
  }
}

.TISESlider-nav {
  z-index: 1;
  position: absolute;
  top: 50%;
  padding: rem-calc(10);
  transform: translateY(-50%);

  &::after {
    content: '';
    display: block;
    width: rem-calc(17);
    height: rem-calc(30);
    transition: transform .65s ease(out-quint);
  }

  &--previous {
    left: rem-calc(20);

    &::after {
      @include sprite('arrow-left', (fill: map-get($color-brand, 'blue')));
    }

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        &::after {
          transform: translateX(-10px);
        }
      }
    }
  }

  &--next {
    right: rem-calc(20);

    &::after {
      @include sprite('arrow-right', (fill: map-get($color-brand, 'blue')));
    }

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        &::after {
          transform: translateX(10px);
        }
      }
    }
  }

  @include breakpoint(medium down) {
    display: none;
  }
}
