/*! Controls Component */

.Controls {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
  }
}

.Controls-container {
  @extend %container;
}

.Controls-tabs {
  display: flex;
}

.Control {
  &:not(:last-child) {
    margin-right: rem-calc(30);
  }

  @include breakpoint(medium) {
    &:not(:last-child) {
      margin-right: rem-calc(50);
    }
  }

  &.is-active {
    .Control-title {
      color: map-get($color-brand, 'blue');
    }
  }
}

.Control-title {
  display: inline-flex;
  align-items: center;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  font-size: rem-calc(16);
  font-weight: 700;
  outline: none;
  transition: color .4s ease(out-quart);

  @include breakpoint(medium) {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    font-size: rem-calc(20);
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      color: map-get($color-brand, 'blue');

      &::after {
        @include sprite('arrow-down', (fill: map-get($color-brand, 'blue')));
      }
    }
  }

  &::after {
    content: '';
    @include sprite('arrow-down');
    display: inline-block;
    width: rem-calc(16);
    height: rem-calc(16);
    margin-left: rem-calc(10);
    transition: transform .6s ease(out-quint), background 0s linear .075s;

    @include breakpoint(medium) {
      width: rem-calc(20);
      height: rem-calc(20);
      margin-left: rem-calc(15);
    }

    .is-active & {
      @include sprite('arrow-down', (fill: map-get($color-brand, 'blue')));
      transform: rotateX(180deg);
    }
  }
}

.Control-content {
  display: none;
  padding-top: rem-calc(20);

  &.is-active {
    display: block;
  }
}

.Control-list {
  @include xy-grid;
}

.Control-item {
  margin-bottom: rem-calc(15);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(25);
  }

  &:not(:last-child) {
    margin-right: rem-calc(15);

    @include breakpoint(medium) {
      margin-right: rem-calc(30)
    }
  }
}

.Control-button {
  display: inline-flex;
  align-items: center;
  height: rem-calc(40);
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
  font-size: rem-calc(16);
  background-color: $color-white;
  border-radius: rem-calc(23);
  box-shadow: rem-calc(0 0 15 5) $shadow-soft;
  transition: color .4s ease(out-quint), background-color .4s ease(out-quint);

  @include breakpoint(medium) {
    height: rem-calc(46);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      color: $color-white;
      background-color: map-get($color-brand, 'blue');
    }
  }

  &.is-selected {
    color: $color-white;
    background-color: map-get($color-brand, 'blue');

    &::after {
      content: '';
      @include sprite('close', (fill: $color-white));
      width: rem-calc(14);
      height: rem-calc(14);
      margin-left: rem-calc(20);
      background-size: contain;
      transition: transform .3s ease(out-back);
    }

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        background-color: darken(map-get($color-brand, 'blue'), 10);

        &::after {
          transform: scale(1.25);
        }
      }
    }

    &.cross-disabled {
      &::after {
        display: none;
      }
    }
  }
}

.Controls-archives {
  margin-top: rem-calc(20);
}

.Controls-archivesCheckbox {
  input {
    @extend .sr-only;

    &:checked + label {
      color: map-get($color-brand, 'blue');

      &::before {
        box-shadow: inset rem-calc(0 0 0 9) map-get($color-brand, 'blue');
        transition: box-shadow .55s ease(out-quint);
      }
    }

    #{$keyboard} &:focus + label {
      color: red;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    font-size: rem-calc(16);
    font-weight: 300;
    transition: color .4s ease(out-quint);

    &::before {
      content: '';
      display: inline-block;
      width: rem-calc(24);
      height: rem-calc(24);
      margin-right: rem-calc(30);
      background-color: $color-white;
      border-radius: rem-calc(12);
      box-shadow: inset rem-calc(0 0 0 2) map-get($color-brand, 'blue');
      transition: box-shadow .4s ease(out-quint);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
