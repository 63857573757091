/*! AnchorMenu component */

.AnchorMenu {
  z-index: 1;
  position: relative;
  background-color: $color-lightgray;
  transition: transform .4s ease(out-quart), opacity .2s ease(out-quart), visibility 0s linear;

  &--is-sticked {
    position: fixed;
    top: rem-calc(90);
    left: 0;
    width: 100%;

    @include breakpoint(large) {
      body:not(.header-reduced) & {
        transform: translateY(rem-calc(90));
      }
    }

    & + .RichText {
      margin-top: rem-calc(60);
    }
  }

  &--is-invisible {
    opacity: 0;
    transform: translateY(-100%);
    visibility: hidden;
    transition: transform .4s ease(out-quart), opacity .2s ease(out-quart) .2s, visibility 0s linear .4s;
  }
}

.AnchorMenu-toggle {
  position: relative;
  display: block;
  width: 100%;
  height: rem-calc(60);
  padding-left: rem-calc(20);
  padding-right: rem-calc(62);
  font-size: rem-calc(20);
  font-weight: 700;
  text-align: left;
  color: $color-white;
  background-color: map-get($color-brand, 'blue');
  box-shadow: inset rem-calc(0 1 0 0) rgba($color-white, .5);

  &::before {
    content: attr(data-index)'/'attr(data-total-index);
    margin-right: rem-calc(10);
  }

  &::after {
    @include sprite('arrow-down', (fill: $color-white));
    content: '';
    position: absolute;
    top: 50%;
    right: rem-calc(20);
    width: rem-calc(22);
    height: rem-calc(22);
    transform: translateY(-50%);
    transition: transform .6s ease(out-quint);
  }

  &.menu-open {
    &::after {
      transform: translateY(-50%) rotateX(180deg);
    }

    & + .AnchorMenu-content {
      @include breakpoint(medium down) {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, opacity .55s ease(out-quint);
      }
    }
  }

  @include breakpoint(large) {
    display: none;
  }
}

.AnchorMenu-content {
  display: flex;

  @include breakpoint(medium down) {
    position: absolute;
    top: 100%;
    left: 0;
    flex-wrap: wrap;
    width: 100%;
    max-height: rem-calc(270);
    background-color: $color-lightgray;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear .25s, opacity .25s ease(out-quint);
  }
}

.AnchorMenu-item {
  width: 100%;

  @include breakpoint(large) {
    flex: 1;
    width: auto;
    max-width: 25%;
  }

  &:not(:first-child) {
    @include breakpoint(large) {
      .AnchorMenu-link {
        padding-left: rem-calc(35);
      }
    }

    .AnchorMenu-link.is-active,
    .AnchorMenu-link:hover,
    #{$keyboard} .AnchorMenu-link:focus {
      &::before {
        visibility: visible;
      }
    }
  }
}

.AnchorMenu-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem-calc(60);
  padding: rem-calc(10 20);
  font-size: rem-calc(17);
  line-height: 1.2;
  text-align: center;
  transition: color .4s ease(out-quint), background-color .4s ease(out-quint);

  @include breakpoint(large) {
    position: relative;
    padding: rem-calc(10 15);
    text-align: initial;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: rem-calc(20);
      visibility: hidden;
      height: 100%;
      transition: border-color .4s ease(out-quint), visibility .0s linear .4s;
    }

    &::before {
      left: 0;
      border-left: solid rem-calc(20) $color-lightgray;
      border-top: solid rem-calc(30) transparent;
      border-bottom: solid rem-calc(30) transparent;
    }

    &::after {
      z-index: 2;
      left: 100%;
      border-left: solid rem-calc(20) transparent;
      border-top: solid rem-calc(30) transparent;
      border-bottom: solid rem-calc(30) transparent;
    }

    &.is-active,
    &:hover,
    #{$keyboard} &:focus {
      outline: none;
      color: $color-white;
      background-color: lighten(map-get($color-brand, 'blue'), 10);

      &::before,
      &::after {
        transition: border-left .4s ease(out-quint), visibility .0s linear;
      }

      &::before {
        border-top: solid rem-calc(30) transparent;
        border-bottom: solid rem-calc(30) transparent;
      }

      &::after {
        visibility: visible;
        border-left: solid rem-calc(20) lighten(map-get($color-brand, 'blue'), 10);
      }
    }
  }

  &.is-active {
    font-weight: 500;
    color: $color-white;
    background-color: map-get($color-brand, 'blue');

    @include breakpoint(large) {
      &::after {
        visibility: visible;
        border-left: solid rem-calc(20) map-get($color-brand, 'blue');
      }
    }
  }
}
