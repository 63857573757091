/*! Testimonials Carousel component */

.TestimonialsCarousel {
  padding-bottom: rem-calc(35);
  background:
    url(asset("../images/testimonials.png")) bottom rem-calc(40) left rem-calc(-220) no-repeat,
    url(asset("../images/testimonials-2.png")) bottom rem-calc(140) right rem-calc(-50) no-repeat;

  @include breakpoint(medium) {
    padding-bottom: rem-calc(150);
    background:
      url(asset("../images/testimonials.png")) bottom rem-calc(-15) left/rem-calc(230) no-repeat,
      url(asset("../images/testimonials-2.png")) bottom rem-calc(-15) right/rem-calc(170) no-repeat;
  }

  @include breakpoint(large) {
    background:
      url(asset("../images/testimonials.png")) bottom rem-calc(-5) left no-repeat,
      url(asset("../images/testimonials-2.png")) bottom rem-calc(50) right rem-calc(15) no-repeat;
  }

  .Testimonial {
    @include sprite('quotes-open', (fill: map-get($color-brand, 'red')));
    height: 100%;
    padding: rem-calc(80 25 25);
    background-color: $color-white;
    background-size: rem-calc(50) auto;
    background-position: top rem-calc(25) left rem-calc(30);

    @include breakpoint(medium) {
      padding: rem-calc(120 100 70);
      box-shadow: none;
      border-radius: none;
      background-size: rem-calc(70 50);
      background-position: top rem-calc(45) left rem-calc(100);
    }
  }

  .Testimonial-dropdown {
    max-height: rem-calc(500);

    @include breakpoint(large) {
      min-height: rem-calc(500);
    }
  }
}

.TestimonialsCarousel-heading {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(250);
  background-color: map-get($color-brand, 'blue');
  background-size: auto 500%;

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(180);
    background-size: 150% auto;
    background-repeat: repeat-y;
  }

  // specific style for IE11
  body.explorer & {
    background-size: 500% auto;

    @include breakpoint(medium) {
      background-size: 500% auto;
    }
  }
}

.TestimonialsCarousel-title {
  @extend %container;
  text-align: center;
  color: $color-white;
}

.TestimonialsCarousel-container {
  @extend %container;
  @include xy-grid;
  margin-top: rem-calc(-200);
  margin-bottom: rem-calc(15);

  @include breakpoint(medium) {
    margin-top: rem-calc(-100);
    margin-bottom: rem-calc(80);
  }
}

.TestimonialsCarousel-content {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
    @include xy-cell-offset(1, $gutter-type: none);
  }
}

.TestimonialsCarousel-slider {
  @include xy-gutters($negative: true);
  padding-bottom: rem-calc(55);
  overflow: visible;

  @include breakpoint(medium) {
    position: relative;
    padding-bottom: 0;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      left: 50%;
      bottom: rem-calc(-15);
      width: calc(100% - #{rem-calc(60)});
      height: 100%;
      border-radius: rem-calc(40);
      background-color: $color-white;
      box-shadow: rem-calc(0 7 20 -5) $shadow-soft;
      transform: translateX(-50%);
    }
  }
}

.TestimonialsCarousel-list {
  @include breakpoint(medium) {
    border-radius: rem-calc(45);
    box-shadow: rem-calc(0 7 15 -5) $shadow-soft;
    overflow: hidden;
  }
}

.TestimonialsCarousel-item {
  @include xy-cell(11);
  height: auto;

  @include breakpoint(medium) {
    @include xy-cell(12);

    &.swiper-slide-active {
      opacity: 1 !important; // Fix Swiper opacity bug
    }
  }
}

.TestimonialsCarousel-pagination {
  width: 100%;

  @include breakpoint(medium) {
    bottom: rem-calc(20) !important;
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    margin-left: rem-calc(10) !important;
    margin-right: rem-calc(10) !important;
    background-color: $color-divider;
    opacity: 1;
    transition: background-color .4s ease(out-quint);
  }

  .swiper-pagination-bullet-active {
    background-color: map-get($color-brand, 'blue');
  }
}

.TestimonialsCarousel-nav {
  z-index: 1;
  position: absolute;
  top: 50%;
  padding: rem-calc(10);
  transform: translateY(-50%);

  &::after {
    content: '';
    display: block;
    width: rem-calc(17);
    height: rem-calc(30);
    transition: transform .65s ease(out-quint);
  }

  &--previous {
    left: rem-calc(30);

    &::after {
      @include sprite('arrow-left', (fill: map-get($color-brand, 'blue')));
    }

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        &::after {
          transform: translateX(-10px);
        }
      }
    }
  }

  &--next {
    right: rem-calc(30);

    &::after {
      @include sprite('arrow-right', (fill: map-get($color-brand, 'blue')));
    }

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        &::after {
          transform: translateX(10px);
        }
      }
    }
  }

  @include breakpoint(small only) {
    display: none;
  }
}

.TestimonialsCarousel-buttons {
  @extend %container;
  @include xy-grid;
  justify-content: center;
}

.TestimonialsCarousel-button {
  @include xy-cell(12, $gutters: 0);

  @include breakpoint(small only) {
    text-align: center;

    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 1;
      margin-bottom: rem-calc(10);
    }
  }

  @include breakpoint(medium) {
    @include xy-cell('shrink', $gutters: rem-calc(40));
  }
}
