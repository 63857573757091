/*! Testimonial */

.Testimonial {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: rem-calc(25);
  border-radius: rem-calc(20);
  background-color: $color-white;
  box-shadow: rem-calc(0 7 15 -5) $shadow-soft;

  @include breakpoint(medium) {
    padding: rem-calc(40 50);
    border-radius: rem-calc(45);
  }
}

.Testimonial-content {
  margin-bottom: rem-calc(10);
}

.Testimonial-text {
  font-weight: 300;

  @include breakpoint(small only) {
    font-size: rem-calc(14);
  }
}

.Testimonial-duration {
  font-size: rem-calc(12);

  @include breakpoint(medium) {
    font-size: rem-calc(14);
  }
}

// Specific style for Testimonial dropdown
.Testimonial-dropdown {
  max-height: rem-calc(360);

  @include breakpoint(large) {
    min-height: rem-calc(360);
  }

  .Testimonial-content {
    position: relative;
    overflow: hidden;
  }

  .Testimonial-more {
    position: absolute;
    bottom: 0;
    display: none;
    align-items: center;
    width: 100%;
    height: rem-calc(40);
    background-color: $color-white;
  }

  .Testimonial-moreButton {
    @include link-underline($inverted: true);
    font-size: rem-calc(14);
    font-weight: 500;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
      font-weight: 700;
    }

    &::before {
      content: attr(aria-label);
    }
  }
}

.Testimonial-dropdown--active {
  &.Testimonial-dropdown--open {
    max-height: initial !important;

    .Testimonial-content {
      padding-bottom: rem-calc(40);
    }
  }

  body.explorer & {
    &.Testimonial-dropdown--open {
      max-height: 100% !important;
    }
  }

  .Testimonial-more {
    display: flex;
  }
}
