/*! RichText Video Component */

.KaliopPlayer {
  margin-top: rem-calc(35);
  margin-bottom: rem-calc(35);

  @include breakpoint(medium) {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
  }

  .RichText-videoTranscription {
    display: flex;
    justify-content: flex-end;
    margin-top: rem-calc(15);
  }
}

.KaliopPlayer-infos {
  .KaliopPlayer-infosTitle {
    margin-top: 0;
    margin-bottom: rem-calc(20);
    font-size: rem-calc(24);
    font-weight: 700;
    color: map-get($color-brand, 'blue');
  }

}

.KaliopPlayer-description {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-weight: 300;
}

.KaliopPlayer-player {
  @include breakpoint(medium) {
    position: relative;
    height: 0;
    padding-top: 56.25%;
  }

  video {
    width: 100%;
    height: auto;
    background-color: $color-black;

    @include breakpoint(small only) {
      max-height: 75vh;
    }

    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      height: 100%;
    }
  }
}

.KaliopPlayer-transcription {
  position: relative;
  display: inline-block;
  padding-top: rem-calc(6);
  padding-bottom: rem-calc(6);
  font-size: rem-calc(16);
  font-weight: 500;
  color: map-get($color-brand, 'blue');

  &:hover,
  #{$keyboard} &:focus {
    outline: none;

    &::before {
      transform: scaleX(.75);
    }

    &::after  {
      transform: scaleX(.75);
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: rem-calc(1);
    background-color: $color-black;
    transition: transform .65s ease(out-quint);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
