/*! TIS Employeurs page specific style */

.tis_employer {
  .main {
    padding-top: 0;
  }

  .Hero-title {
    font-weight: 300;
    line-height: .9;

    @include breakpoint(small only) {
      font-size: rem-calc(25);
    }

    span {
      display: inline-block;
      transform: translateY(-25px);

      @include breakpoint(medium) {
        transform: translateY(-35px);
      }
    }

    strong {
      font-size: rem-calc(58);
      font-weight: 500;

      @include breakpoint(medium) {
        font-size: rem-calc(100);
      }
    }
  }
}
