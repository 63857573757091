/*! Pagination Component */

.Pagination {
  position: relative;
  z-index: 2;
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);

  &--hasParent {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--white {
    color: $color-white;

    .Pagination-link {
      @include breakpoint(large) {
        &:hover,
        #{$keyboard} &:focus {
          color: $color-white;
        }
      }
    }
  }
}

.Pagination-container {
  @extend %container;
}

.Pagination-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  justify-content: center;
  align-items: center;
}

.Pagination-item {
  @include xy-cell(shrink, $gutter-type: none);
  font-size: rem-calc(16);
}

.Pagination-item--previous,
.Pagination-item--next {
  @include xy-cell(shrink);
  font-weight: 700;

  .Pagination--hasPages & {
    @include breakpoint(small only) {
      .Pagination-linkLabel {
        display: none;
      }
    }
  }

  @include breakpoint(medium) {
    @include xy-cell(shrink, $gutters: rem-calc(30));
  }

  .Pagination-link {
    display: inline-flex;
    align-items: center;

    @include breakpoint(small only) {
      padding: rem-calc(10);
    }

    .icon {
      display: inline-block;
      width: rem-calc(12);
      height: rem-calc(17);
      transition: transform .4s ease(out-quint);

      @include breakpoint(medium) {
        width: rem-calc(11);
        height: rem-calc(14);
      }
    }
  }
}

.Pagination-item--current {
  font-weight: 700;
}

.Pagination-item {
  &--previous {
    .Pagination-link {
      .Pagination-linkLabel {
        margin-left: rem-calc(10);
      }

      @include breakpoint(large) {
        &:hover,
        #{$keyboard} &:focus {
          .icon {
            transform: translateX(-5px);
          }
        }
      }
    }
  }

  &--next {
    .Pagination-link {
      .Pagination-linkLabel {
        margin-right: rem-calc(10);
      }

      @include breakpoint(large) {
        &:hover,
        #{$keyboard} &:focus {
          .icon {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}

.Pagination-link {
  @include breakpoint(large) {
    transition: color .4s ease(out-quint);

    &:hover,
    #{$keyboard} &:focus {
      color: map-get($color-brand, 'blue');
    }
  }
}

.Pagination-page {
  display: inline-block;
  padding: rem-calc(10 10);

  @include breakpoint(medium) {
    padding: rem-calc(5 10);
  }
}
