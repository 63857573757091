/*! Map Search Component */

.MapSearch {
  background-image: url(asset("../images/map.jpg"));
  background-repeat: no-repeat;
  background-size: cover;
}

.MapSearch-container {
  @extend %container;
  @include xy-grid;
  align-items: center;
  justify-content: center;
  padding-top: rem-calc(80);
  padding-bottom: rem-calc(80);
  min-height: calc(100vh - #{rem-calc(90)});

  @include breakpoint(medium) {
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(150);
  }

  @include breakpoint(large) {
    min-height: calc(100vh - #{rem-calc(180)});
  }
}

.MapSearch-content {
  max-width: rem-calc(650);
  text-align: center;
}

.MapSearch-title {
  margin-bottom: rem-calc(15);
  color: map-get($color-brand, 'blue');
}

.MapSearch-introduction {
  margin-bottom: rem-calc(25);
  color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
