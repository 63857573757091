/*! Menu Component */

.Menu-content {
  @include xy-grid;

  @include breakpoint(medium down) {
    padding-bottom: rem-calc(1);
  }

  @include breakpoint(large) {
    flex-wrap: nowrap;
    justify-content: space-around;
  }
}

.Menu-item {
  @include breakpoint(medium down) {
    width: 100%;
    box-shadow: rem-calc(0 1 0 0) rgba($color-divider, .23);
  }

  @include breakpoint(large) {
    &.is-active {
      .Menu-link {
        color: map-get($color-brand, 'blue');
      }
    }
  }

  &.is-dropdown-submenu-parent {
    .Menu-link {
      @include breakpoint(medium down) {
        @include sprite('arrow-right');
        background-size: rem-calc(10 17);
        background-position: center right rem-calc(3);
      }
    }
  }
}

.Menu-link {
  display: inline-flex;
  align-items: center;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);

  @include breakpoint(medium down) {
    width: 100%;
    padding-left: rem-calc(3);
    padding-right: rem-calc(20);
    font-weight: 700;
  }

  @include breakpoint(large) {
    @include link-color;
    width: initial;
    height: rem-calc(90);
    padding-left: calc(#{map-get($grid-margin-gutters, large)} / 2);
    padding-right: calc(#{map-get($grid-margin-gutters, large)} / 2);
    font-size: rem-calc(15.4);
    font-weight: 500;
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(17);
  }
}

.Menu-subContainer {
  position: absolute;

  &.js-dropdown-active {
    @include breakpoint(medium down) {
      transform: translateX(0);
      opacity: 1;
      transition: transform .55s ease(out-expo), opacity .35s ease(out-quint);
    }

    @include breakpoint(large) {
      visibility: visible;
      transition: visibility 0s linear;

      &::after {
        opacity: 1;
        transition: opacity .55s ease(out-quint);
      }
    }

    .Menu-drilldownBack {
      transform: translateX(0);
    }

    @for $i from 1 through 6 {
      .Menu-subItem:nth-child(#{$i}) {
        opacity: 1;
        transform: translate(0);
        transition: opacity .8s ease(out-quint) $i * .1s + .05s, transform 1s ease(out-quint) $i * .1s + .05s;
      }
    }
  }

  @include breakpoint(medium down) {
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: rem-calc(90);
    background-color: $color-white;
    overflow: auto;
    opacity: 0;
    transform: translateX(100%);
    transition: transform .35s ease(out-quint), opacity .20s ease(out-quart) .15s;
  }

  @include breakpoint(large) {
    visibility: hidden;
    top: 100%;
    left: 50%;
    width: 101vw;
    transform: translateX(-50%);
    transition: visibility 0s linear .25s;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $color-white;
      transition: opacity .25s ease(out-quint);
    }
  }
}

.Menu-drilldownBack {
  @include xy-cell(12, $gutters: 40px);
  display: inline-block;
  transform: translateX(10px);
  transition: transform 1s ease(out-quint);

  @include breakpoint(large) {
    display: none;
  }
}

.Menu-drilldownBackButton {
  @include sprite('arrow-left');
  outline: none;
  padding: rem-calc(25);
  font-size: rem-calc(15);
  font-weight: 300;
  background-size: rem-calc(10 17);
  background-position: center left;
}

.Menu-subContent {
  @extend %container;
  padding-bottom: rem-calc(45);

  @include breakpoint(large) {
    @include xy-grid;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);
  }
}

.Menu-subItem {
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);

  @include breakpoint(medium down) {
    width: 100%;
    transform: translateY(10px);

    &:not(:last-child) {
      margin-bottom: rem-calc(25);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(4);
    max-width: rem-calc(300);
    transform: translateY(-5px);
  }

  .Card-content {
    min-height: rem-calc(140);
  }
}
