/*! TileLink component */

.revert-color {
  .TileLink {
    background-color: map-get($color-brand, 'blue');
    color: $color-white;

    .TileLink-container {
      @include sprite('arrow-right', (fill: $color-white));
      background-size: rem-calc(22 44);
      background-position: bottom rem-calc(35) right rem-calc(20);

      @include breakpoint(large) {
        background-position: center right rem-calc(35);
        background-size: rem-calc(15 30);
      }
    }

    &:nth-child(even) {
      background-color: $color-lightgray;
      color: $color-black;

      .TileLink-container {
        @include sprite('arrow-right');
        background-size: rem-calc(22 44);
        background-position: bottom rem-calc(35) right rem-calc(20);

        @include breakpoint(large) {
          background-position: center right rem-calc(35);
          background-size: rem-calc(15 30);
        }
      }
    }
  }

  .TileLink-link {
    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        & + .TileLink-container {
          background-position: center right rem-calc(15);
          transition: background-position .65s ease(out-quint);
        }
      }
    }
  }
}

.TileLink {
  position: relative;
  background-color: $color-lightgray;

  &:nth-child(even) {
    background-color: map-get($color-brand, 'blue');
    color: $color-white;

    .TileLink-container {
      @include sprite('arrow-right', (fill: $color-white));
      background-size: rem-calc(22) auto;
      background-position: bottom rem-calc(35) right rem-calc(20);

      @include breakpoint(large) {
        background-position: center right rem-calc(35);
        background-size: rem-calc(15) auto;
      }
    }
  }
}

.TileLink-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      & + .TileLink-container {
        background-position: center right rem-calc(15);
        transition: background-position .65s ease(out-quint);
      }
    }
  }
}

.TileLink-container {
  @extend %container;
  @include sprite('arrow-right');
  padding-top: rem-calc(80);
  padding-bottom: rem-calc(120);
  background-size: rem-calc(22) auto;
  background-position: bottom rem-calc(35) right rem-calc(20);
  transition: background-position .35s ease(out-quint);

  @include breakpoint(large) {
    padding-top: rem-calc(120);
    background-position: center right rem-calc(35);
    background-size: rem-calc(15) auto;
  }
}

.TileLink-text {
  @include breakpoint(large) {
    width: 75%;
  }
}

.TileLink-title {
  margin-bottom: rem-calc(15);
}
