/*! Nav Mobile Component */

.NavMobile {
  @include breakpoint(medium down) {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transition: visibility 0s linear .55s;
  }
}

.NavMobile-overlay {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color: #000, $amount: .5);
  opacity: 0;
  transition: opacity .45s ease(out-quad) .1s;

  @include breakpoint(large) {
    display: none;
  }
}

.NavMobile-container {
  @include breakpoint(medium down) {
    @include xy-grid-container($width: 375px, $padding: 40px);
    margin-right: 0;
    padding-top: rem-calc(90);
    background-color: $color-white;
    height: 100%;
    opacity: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(100%);
    transition: transform .35s ease(out-quint), opacity .20s ease(out-quart) .15s;
  }
}

.NavMobile-content {
  @include breakpoint(medium down) {
    display: block;
    height: 100%;
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(80);
  }
}

.NavMobile-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint(large) {
    position: initial;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-height: initial;
    margin-left: rem-calc(110);
    overflow: initial;
  }
}

.NavMobile-search {
  @include breakpoint(medium down) {
    order: 1;
  }
}

.NavMobile-menu {
  @include breakpoint(medium down) {
    order: 2;
  }
}

body.nav-open {
  overflow: hidden;

  .site-wrapper {
    overflow: hidden;
  }

  .NavMobile {
    visibility: visible;
    transition: visibility 0s linear;
  }

  .NavMobile-overlay {
    opacity: 1;
    transition: opacity .35s ease(out-quad);
  }

  .NavMobile-container {
    opacity: 1;
    transform: translateX(0);
    transition: transform .55s ease(out-expo), opacity .35s ease(out-quint);
  }
}

