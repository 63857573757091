/*! PreFooter component */

.PreFooter {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(30);
  background-color: $color-white;

  @include breakpoint(large) {
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(60);
  }

  @include breakpoint(xlarge) {
    padding-bottom: rem-calc(80);
  }
}

.PreFooter-container {
  @extend %container;
}

.PreFooter-title {
  margin-bottom: rem-calc(30);

  @include breakpoint(large) {
    margin-bottom: rem-calc(45);
  }
}

.PreFooter-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  justify-content: center;
}

.PreFooter-item {
  @include xy-cell(12);
  margin-bottom: rem-calc(20);

  @include breakpoint(medium) {
    @include xy-cell(6);
  }

  @include breakpoint(large) {
    @include xy-cell(4.5);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3);
    margin-bottom: 0;

    .Card-title {
      font-size: rem-calc(19);
    }
  }
}
