/*! Divider Component */

.Divider-container {
  @extend %container;
}

.Divider-divider {
  height: rem-calc(1);
  background-color: rgba($color-divider, .5);
}
