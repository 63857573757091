/*! Search Autocomplete Component */

.SearchAutocomplete-item {
  &:first-child {
    margin-top: rem-calc(50);
  }

  &:not(:last-child) {
    box-shadow: inset rem-calc(0 -1 0 0) rgba($color-divider, .4);
  }
}

.SearchAutocomplete-link {
  @include sprite('arrow-right');
  display: block;
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(25);
  padding-right: rem-calc(40);
  font-size: rem-calc(20);
  background-size: rem-calc(10 17);
  background-position: center right rem-calc(10);
  transition: color .4s ease(out-quint) ,background-position .65s ease(out-quint);

  @include breakpoint(large) {
    padding-right: rem-calc(55);
    background-position: center right rem-calc(25);

    &:hover,
    #{$keyboard} &:focus {
      @include sprite('arrow-right', (fill: map-get($color-brand, 'blue')));
      background-size: rem-calc(10 17);
      background-position: center right rem-calc(10);
      color: map-get($color-brand, 'blue');
    }
  }

  &--truncated {
    .word:not([style*="line-index:0"]) {
      @extend .sr-only;
    }

    &::after {
      content: '...';
    }
  }
}
