/*! Init page styles */

// Default box model
*,
::before,
::after {
  box-sizing: border-box;
}

// Document
html {
  font-size: $global-font-size;
}

body {
  // Optimize text rendering on all Browsers & OS
  @include osx-font-smoothing-grayscale;
  font-family: $font-main;
  font-size: rem-calc(17);
  line-height: $global-lineheight;
  color: $color-black;

  @include breakpoint(medium) {
    font-size: rem-calc(18);
  }
}

// Prevent horizontal scroll on every support
.site-wrapper {
  overflow-x: hidden;
  background-color: $color-lightgray;
}

// Global container properties
%container {
  @include xy-grid-container($padding: rem-calc(40));

  @include breakpoint(large) {
    @include xy-grid-container($padding: rem-calc(30));
  }
}

// Additionnal reset styles for very common stuff
// Normalize tries to only remove differences between browsers.
// If we want an actual reset, we need to reset the styles of
// just a handful of elements.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
}

.h1-style {
  font-size: rem-calc(35);
  font-weight: 700;
  line-height: 1.1;

  @include breakpoint(medium) {
    font-size: rem-calc(55);
  }
}

.h2-style {
  font-size: rem-calc(28);
  font-weight: 700;
  line-height: 1.3;

  @include breakpoint(medium) {
    font-size: rem-calc(35);
  }
}

.h3-style {
  font-size: rem-calc(20);
  font-weight: 700;
  line-height: 1.3;
}

.h4-style {
  font-weight: 300;
  line-height: 1.3;
}

.h5-style {
  font-size: rem-calc(14);
  line-height: 1.3;
}

p,
ul,
ol,
figure,
blockquote {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

// Reset button styles.
// It takes a bit of work to achieve a neutral look.
button {
  padding: 0;
  border: none;
  font: inherit;
  line-height: 1;
  color: inherit;
  background-color: transparent;

  // show a hand cursor on hover
  &:hover {
    cursor: pointer;
  }
}

details > summary {
  cursor: pointer;
  user-select: none;
}

// Here we contradict Normalize
fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

// Force images and frames to fit their container at most
img,
iframe {
  width: 100%;
  max-width: 100%;
}

picture img {
  width: 100%;
}

// Other small fixes
::-ms-clear {
  display: none;
}

.main {
  display: block; // Fix "main" padding top on ie11
  padding-top: rem-calc(90);

  @include breakpoint(large) {
    padding-top: rem-calc(180);
  }
}

// hide element only visually
.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
