// ======
// Mixins
// ======

// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@mixin link-underline($inverted: false) {
  display: inline;
  line-height: 1.5;
  background-image: linear-gradient(to right, currentColor, currentColor);
  background-repeat: no-repeat;
  transition: background-size 0.8s ease(out-quint);

  @if $inverted {
    background-size: 100% rem-calc(1);
    background-position: right bottom 10%;
  } @else {
    background-size: 0 rem-calc(1);
    background-position: left bottom;
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      outline: none;

      @if $inverted {
        background-size: 0 rem-calc(1);
      } @else {
        background-size: 100% rem-calc(1);
      }
    }
  }
}

@mixin link-color {
  transition: color 0.4s ease(out-quint);

  &:hover,
  #{$keyboard} &:focus {
    color: map-get($color-brand, 'blue');
  }
}

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
@mixin scrimGradient($direction: 'to bottom', $startColor: #000) {
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background-image: linear-gradient(unquote($direction), $stops);
}
