/*! Calculator component */

.Calculator {
  position: relative;
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-lightgray;

  &::after {
    content: '';
    @include sprite('waves', (fill: rgba($color-white, .05)));
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem-calc(250);
    background-color: map-get($color-brand, 'blue');
    background-size: auto 400%;
  }

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(110);

    &::after {
      height: rem-calc(400);
      background-size: 200% auto;
    }
  }

  @include breakpoint(large) {
    position: initial;
    background-color: none;
    background-image: linear-gradient(to right, $color-white 50%, $color-lightgray 50%);

    &::after {
      display: none;
    }
  }
}

.Calculator-heading {
  @extend %container;
  z-index: 2;
  position: relative;
  margin-bottom: rem-calc(115);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(200);
  }

  @include breakpoint(large) {
    position: initial;
    margin-bottom: rem-calc(65);
  }
}

.Calculator-title {
  @include xy-cell(9, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(8, $gutter-type: none);
  }
}

.Calculator-container {
  @include xy-grid-container($padding: rem-calc(40));

  @include breakpoint(large) {
    @include xy-grid-container($width: rem-calc(1440), $padding: rem-calc(30));
    @include xy-grid;
    justify-content: space-between;
  }
}

.Calculator-content {
  z-index: 2;
  position: relative;

  @include breakpoint(large) {
    position: initial;
    @include xy-cell(8, $gutter-type: none);
    @include xy-grid;
    padding: rem-calc(50);
    border-radius: rem-calc(40);
    background-color: $color-white;
    box-shadow: rem-calc(0 7 25 -5) $shadow-strong;
  }

  @include breakpoint(xlarge) {
    padding: rem-calc(85 50 85 85);
  }
}

.Calculator-results {
  @include xy-grid;
  @include xy-gutters($negative: true);
  justify-content: center;
  margin-bottom: rem-calc(30);

  @include breakpoint(large) {
    @include xy-cell(5, $gutter-type: none);
    order: 2;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
    padding-left: rem-calc(50);
  }
}

.Calculator-result {
  @include xy-cell(6);
  text-align: center;

  &:last-child {
    @include breakpoint(medium down) {
      @include xy-cell(12);
      margin-top: rem-calc(10);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(12);
    padding-left: 0;
    padding-right: 0;
  }
}

.Calculator-resultValue {
  display: block;
  margin-bottom: rem-calc(5);
  font-size: rem-calc(26);
  font-weight: 700;
  line-height: 1;
  color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    margin-bottom: rem-calc(10);
    font-size: rem-calc(40);
  }
}

.Calculator-resultLabel,
.Calculator-resultSublabel {
  text-transform: uppercase;

  @include breakpoint(medium) {
    font-weight: 300;
  }
}

.Calculator-resultLabel {
  font-size: rem-calc(12);

  @include breakpoint(medium) {
    font-size: rem-calc(16);
  }
}

.Calculator-resultSublabel {
  font-size: rem-calc(14);

  @include breakpoint(medium) {
    font-size: rem-calc(20);
  }
}

.Calculator-form {
  @include breakpoint(medium down) {
    padding: rem-calc(50);
    border-radius: rem-calc(25);
    background-color: $color-white;
    box-shadow: rem-calc(0 7 15 5) $shadow-strong;
  }

  @include breakpoint(small only) {
    padding: rem-calc(30);
  }

  @include breakpoint(large) {
    @include xy-cell(7, $gutter-type: none);
    order: 1;
    padding-right: rem-calc(50);
    border-right: rem-calc(2) solid $color-lightgray;
  }
}

.Calculator-formItem {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(50);
    }
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(50);
  }

  & > label {
    display: block;
    margin-bottom: rem-calc(20);
    font-size: rem-calc(18);
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: rem-calc(22);
    }
  }

  .input-range {
    max-width: rem-calc(270);
    margin-top: rem-calc(28);
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(medium only) {
      max-width: rem-calc(400);
    }

    @include breakpoint(medium) {
      margin-top: rem-calc(40);
    }

    @include breakpoint(large) {
      max-width: rem-calc(300);
      margin-left: initial;
      margin-right: initial;
    }
  }

  .input-range__track--background {
    background-color: $color-gray;
  }

  .input-range__track--active {
    background-color: map-get($color-brand, 'blue');
  }

  .input-range__slider {
    width: rem-calc(20);
    height: rem-calc(20);
    margin-left: rem-calc(-10);
    margin-top: rem-calc(-12);
    background-color: $color-white;
    box-shadow: inset rem-calc(0 0 0 3) map-get($color-brand, 'blue');
    border: none;

    &:active {
      transform: scale(1.2);
    }
  }

  .input-range__label--value {
    top: rem-calc(-35);
    font-family: $font-main;
    font-size: rem-calc(16);
    font-weight: 300;
    color: map-get($color-brand, 'blue');

    @include breakpoint(medium) {
      top: rem-calc(-42);
      font-size: rem-calc(20);
    }
  }

  .input-range__label--min,
  .input-range__label--max {
    display: none;
  }

  &.Calculator-formItem--price {
    .input-range__label--value .input-range__label-container {
      &::after {
        content: '€';
      }
    }
  }
}

.Calculator-formRadios {
  display: flex;
  justify-content: center;

  @include breakpoint(large) {
    justify-content: initial;
  }
}

.Calculator-formRadio {
  display: inline-block;

  &:not(:last-child) {
    margin-right: rem-calc(50);

    @include breakpoint(medium) {
      margin-right: rem-calc(60);
    }
  }

  input {
    @extend .sr-only;

    &:checked + label {
      font-weight: 700;
      color: $color-white;
      background-color: map-get($color-brand, 'blue');
      box-shadow: rem-calc(0 0 5 5) $shadow-soft;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(45);
    height: rem-calc(45);
    font-size: rem-calc(16);
    font-weight: 300;
    color: $color-divider;
    border-radius: rem-calc(15);
    transition: color .4s ease(out-quart), background-color .4s ease(out-quart), box-shadow .4s ease(out-quart);

    @include breakpoint(medium) {
      width: rem-calc(55);
      height: rem-calc(55);
      font-size: rem-calc(20);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.Calculator-images {
  position: absolute;
  top: rem-calc(15);
  right: rem-calc(-5);
  width: 40%;
  max-width: rem-calc(160);
  transform: translateY(-8%);

  @include breakpoint(medium) {
    top: 0;
    right: rem-calc(-15);
    width: 50%;
    max-width: rem-calc(300);
  }

  @include breakpoint(large) {
    position: static;
    @include xy-cell(3.5, $gutter-type: none);
    transform: translateY(5%);
  }

  @include breakpoint(xlarge) {
    transform: translateY(-10%);
  }

  &--animated {
    .Calculator-image--static {
      visibility: hidden;
    }

    .Calculator-image--anime {
      visibility: visible;
    }
  }
}

.Calculator-image--anime {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: auto;
}
