/*! Quiz page specific style */

body.form_quiz {
  .CtaBlock {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
    background-color: $color-white;
  }

  .CtaBlock-text {
    font-size: rem-calc(22);
  }

  .RichText:nth-of-type(2) {
    .RichText-container {
      padding-bottom: 0;
    }
  }
}
