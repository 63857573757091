/*! SearchBlock Component */

.SearchBlock {
  padding-bottom: rem-calc(70);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(100);
  }
}

.SearchBlock-heading {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(100);
  background-color: map-get($color-brand, 'blue');
  background-size: auto 500%;

  @include breakpoint(medium) {
    background-size: 150% auto;
  }

  // specific style for IE11
  body.explorer & {
    background-size: 700% auto;

    @include breakpoint(medium) {
      background-size: 700% auto;
    }
  }
}

.SearchBlock-title {
  @extend %container;
  text-align: center;
  color: $color-white;
}

.SearchBlock-button {
  @extend %container;
  text-align: center;

  @include breakpoint(medium) {
    margin-top: rem-calc(30);
  }
}
