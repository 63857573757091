/*! Search Component */

.SearchHeader {
  @include breakpoint(medium down) {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(30);
  }

  .SearchAutocomplete {
    @include xy-cell(8, $gutter-type: none);

    @include breakpoint(medium down) {
      display: none;
    }

    @include breakpoint(large) {
      opacity: 0;
      transform: translateY(-5px);
      transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);
    }
  }
}

.SearchHeader-block {
  @include breakpoint(large) {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transform: translateX(-50%);
    transition: visibility 0s linear .25s, opacity .25s ease(out-quint);
  }
}

.SearchHeader-wrapper {
  @include breakpoint(large) {
    z-index: 2;
    position: relative;
    min-height: rem-calc(400);
    background-color: $color-white;
    border-bottom-left-radius: rem-calc(32);
    border-bottom-right-radius: rem-calc(32);
  }
}

.SearchHeader-overlay {
  @include breakpoint(large) {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color: #000, $amount: .5);
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.SearchHeader-container {
  @include breakpoint(large) {
    @include xy-grid-container($padding: 30px);
    @include xy-grid;
    position: relative;
    justify-content: center;
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(150);
  }
}

.SearchHeader-content {
  @include breakpoint(large) {
    @include xy-cell(9);
    position: relative;
    border-radius: rem-calc(20);
    overflow: hidden;
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);
  }
}

.SearchHeader-close {
  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: 20px, $gutter-type: 'padding');
    position: absolute;
    top: rem-calc(40);
    right: rem-calc(5);
    padding-top: calc(#{map-get($grid-margin-gutters, large)} / 2);
    padding-bottom: calc(#{map-get($grid-margin-gutters, large)} / 2);
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);
  }

  @include breakpoint(medium down) {
    display: none;
  }

  &:hover,
  #{$keyboard} &:focus {
    .icon {
      color: map-get($color-brand, 'blue');
    }
  }

  .icon {
    width: rem-calc(22);
    height: rem-calc(22);
    color: $color-divider;
    transition: color .4s ease(out-quint);
  }
}

.SearchHeader-input {
  @include sprite('search', (fill: $color-divider));
  background-color: $color-gray;

  @include breakpoint(medium down) {
    display: inline-block;
    width: 100%;
    height: rem-calc(45);
    padding-left: rem-calc(55);
    padding-right: rem-calc(25);
    font-size: rem-calc(16);
    border-radius: rem-calc(26);
    background-size: rem-calc(16 18);
    background-position: rem-calc(20) center;
  }

  @include breakpoint(large) {
    height: rem-calc(75);
    padding-left: rem-calc(100);
    padding-right: rem-calc(100);
    font-weight: 300;
    background-size: rem-calc(16 18);
    background-position: rem-calc(50) center;

    &:focus {
      outline: none;
    }
  }
}

.SearchHeader-valid {
  @include sprite('waves', (fill: rgba($color-white, .2)));
  position: absolute;
  top: 0;
  right: 0;
  width: rem-calc(80);
  height: 100%;
  color: $color-white;
  border-top-right-radius: rem-calc(20);
  border-bottom-right-radius: rem-calc(20);
  background-size: rem-calc(600) auto;
  background-position: top 48% center;
  transition: background-position .65s ease(out-quint), background-color .4s ease(out-quint), box-shadow .25s ease(out-quint);
  background-color: map-get($color-brand, 'blue');

  &:hover {
    background-position: top 52% center;
    background-color: darken(map-get($color-brand, 'blue'), 8);
  }

  #{$keyboard} &:focus {
    outline: none;
    box-shadow: inset rem-calc(0 0 0 3) map-get($color-brand, 'yellow');
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.SearchHeader-open {
  display: none;

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutter-type: 'padding');
    display: block;
    padding-top: calc(#{map-get($grid-margin-gutters, large)} / 2);
    padding-bottom: calc(#{map-get($grid-margin-gutters, large)} / 2);
    align-self: center;
    outline: none;

    &:hover,
    #{$keyboard} &:focus {
      .icon {
        color: map-get($color-brand, 'blue');
      }
    }
  }

  .icon {
    width: rem-calc(24);
    height: rem-calc(24);
    color: currentColor;
    transition: color .4s ease(out-quint);
  }
}

body.search-open {
  overflow: hidden;

  .site-wrapper {
    overflow: hidden;
  }

  .SearchHeader-block {
    @include breakpoint(large) {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear, opacity .55s ease(out-quint);
    }
  }

  .SearchHeader-content {
    @include breakpoint(large) {
      opacity: 1;
      transform: translate(0);
      transition: opacity .8s ease(out-quint) .15s, transform 1s ease(out-quint) .15s;
    }
  }

  .SearchHeader-close {
    @include breakpoint(large) {
      opacity: 1;
      transform: translate(0);
      transition: opacity .8s ease(out-quint) .3s, transform 1s ease(out-quint) .3s;
    }
  }

  .SearchHeader .SearchAutocomplete {
    @include breakpoint(large) {
      opacity: 1;
      transform: translate(0);
      transition: opacity .8s ease(out-quint) .45s, transform 1s ease(out-quint) .45s;
    }
  }
}


body.header-reduced {
  @include breakpoint(large) {
    .SearchHeader-block {
      transform: translateX(-50%) translateY(rem-calc(90));
    }
  }
}
