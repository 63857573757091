/*! Quiz page specific style */

.form_structure {
  .Form-item--legend {
    margin-top: 0;

    p {
      font-size: rem-calc(16);
      font-weight: 400;
      color: map-get($color-brand, 'red');
    }
  }

  .Form-radio {
    display: block;
  }
}
