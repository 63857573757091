/* TISEJoin component */

.TISEJoin-heading {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-white;

  h2 {
    margin: 0;
    color: map-get($color-brand, 'blue');
  }
}

.TISEJoin-infos {
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(50);
  background-color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    padding-bottom: rem-calc(110);
  }
}

.TISEJoin-container {
  @extend %container;
}

.TISEJoin-content {
  color: $color-white;

  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(100));
    justify-content: space-between;
    align-items: center;
  }
}

.TISEJoin-text {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(50);
  }

  @include breakpoint(large) {
    @include xy-cell(7, $gutters: rem-calc(100));
  }

  .Button {
    margin-top: rem-calc(35);

    @include breakpoint(small only) {
      width: 100%;
    }
  }
}

.TISEJoin-image {
  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(large) {
    @include xy-cell(5, $gutters: rem-calc(100));
  }
}
