/*! Footer component */

.Footer {
  background-color: $color-lightgray;
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(70);

  @include breakpoint(medium) {
    padding-top: rem-calc(70);
  }

  @include breakpoint(large) {
    padding-bottom: rem-calc(170);
  }
}

.Footer-container {
  @extend %container;
}

.Footer-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  justify-content: space-between;

  @include breakpoint(medium only) {
    position: relative;
    justify-content: center;
  }
}

.Footer-logoTIS {
  @include xy-cell(12);
  margin-bottom: rem-calc(40);

  @include breakpoint(medium) {
    @include xy-cell(10);
  }

  @include breakpoint(large) {
    @include xy-cell(shrink);
    margin-bottom: initial;
  }

  .icon-logo-tis {
    width: rem-calc(95);
    height: rem-calc(52);
    fill: map-get($color-brand, 'blue');
  }
}

.Footer-logoTISLink {
  display: inline-block;
  width: rem-calc(95);
}

.Footer-part {
  @include xy-cell(12);

  &:not(:last-child) {
    margin-bottom: rem-calc(40);
  }

  @include breakpoint(medium) {
    @include xy-cell(10);
  }

  @include breakpoint(large) {
    @include xy-cell(3);

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.Footer-menuItem {
  @include breakpoint(small only) {
    font-size: rem-calc(16);
  }

  &:first-child {
    font-weight: 700;
    font-size: rem-calc(18);
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(15);

    @include breakpoint(large) {
      margin-bottom: rem-calc(25);
    }
  }
}

.Footer-menu:not(.Footer-menu--main) {
  .Footer-menuLink {
    @include breakpoint(large) {
      @include link-underline;
    }
  }
}

.Footer-menu--main {
  .Footer-menuItem {
    font-size: rem-calc(18);
    font-weight: 700;
  }

  .Footer-menuLink {
    @include breakpoint(large) {
      @include link-color;
    }
  }
}

.Footer-social {
  margin-top: rem-calc(30);

  @include breakpoint(medium only) {
    @include xy-cell(shrink);
    position: absolute;
    bottom: 0;
    right: xy-cell-size(1);
    transform: translateY(20%);
  }

  @include breakpoint(large) {
    margin-top: rem-calc(60);
  }

  .Social {
    margin-left: rem-calc(-5);
  }
}
