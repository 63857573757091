/*! Menu button */

.MenuButton {
  @include xy-cell(shrink, $gutter-type: 'padding');
  align-self: center;
  padding-top: calc(#{map-get($grid-margin-gutters, large)} / 2);
  padding-bottom: calc(#{map-get($grid-margin-gutters, large)} / 2);
  outline: none;
}

.MenuButton-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: rem-calc(24);
  height: rem-calc(16);
}

.MenuButton-stripe {
  position: relative;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  transition: top .2s ease(out-quart) .2s, transform .2s ease(in-quart), opacity 0s ease(in-quart) .2s;
}

body.nav-open {
  .MenuButton-stripe {
    transition: top .2s ease(in-quart), transform .2s ease(out-quart) .2s, opacity 0s ease(in-quart) .2s;

    &:first-child {
      top: rem-calc(7);
      transform: rotate(-45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      top: rem-calc(-7);
      transform: rotate(45deg);
    }
  }
}
