/*! CTA Block Component */

.CtaBlock {
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(100);
  background-color: $color-lightgray;

  @include breakpoint(small only) {
    .Button {
      width: 100%;
    }
  }
}

.CtaBlock--waves {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  background-size: auto 500%;

  @include breakpoint(medium) {
    background-size: 150% auto;
  }

  // specific style for IE11
  body.explorer & {
    background-size: 500% auto;

    @include breakpoint(medium) {
      background-size: 500% auto;
    }
  }
}

@each $color, $value in $color-brand {
  .CtaBlock--#{$color} {
    background-color: map-get($color-brand, $color);

    @if $color == 'blue' {
      .CtaBlock-title,
      .CtaBlock-text {
        color: $color-white;
      }
    }
  }
}

.CtaBlock-container {
  @extend %container;
  text-align: center;
}

.CtaBlock-title {
  color: map-get($color-brand, 'red');

  &:not(:only-child) {
    margin-bottom: rem-calc(30);
  }
}

.CtaBlock-text {
  font-size: rem-calc(16);
  font-weight: 300;

  @include breakpoint(large) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.CtaBlock-button {
  &:not(:only-child) {
    margin-top: rem-calc(45);
  }
}
