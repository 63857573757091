/*! Header Component */

.Header {
  background-color: $color-white;

  @include breakpoint(large) {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform .4s ease(out-quart);
  }

  .icon-logo-republique-francaise {
    width: rem-calc(51);
    height: rem-calc(45);

    @include breakpoint(medium) {
      width: rem-calc(68);
      height: rem-calc(60);
    }
  }

  .icon-logo-sante-publique {
    width: rem-calc(67);
    height: rem-calc(38);

    @include breakpoint(medium) {
      width: rem-calc(88);
      height: rem-calc(50);
    }
  }

  .icon-logo-tis {
    width: rem-calc(56);
    height: rem-calc(30);

    @include breakpoint(medium) {
      width: rem-calc(82);
      height: rem-calc(44);
    }
  }
}

.Header-wrapper {
  @extend %container;
  position: relative;
}

.Header-top {
  background-color: $color-white;
  transition: box-shadow .2s ease(out-quad);

  @include breakpoint(medium down) {
    z-index: 110;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .Header-content {
    justify-content: space-between;
  }
}

.Header-container {
  @include breakpoint(medium down) {
    @include xy-grid-container($padding: 40px);
    margin-right: 0;
  }
}

.Header-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
}

.Header-logos {
  display: flex;
}

.Header-logo {
  @include xy-cell(shrink, $gutter-type: 'padding');
  display: inline-flex;
  align-items: center;
  height: rem-calc(90);

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutter-type: 'padding');
  }
}

.Header-logoRF {
  transition: visibility 0s linear;
}

.Header-logoSPF {
  @include xy-cell(shrink, $gutter-type: 'padding');
  transition: visibility 0s linear;

  @include breakpoint(medium) {
    margin-left: rem-calc(15);
  }
}

.Header-logoTIS {
  position: relative;
  margin-left: rem-calc(10);

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 0;
    width: 1px;
    height: rem-calc(55);
    background-color: rgba($color-divider, .3);
    transform: translateY(-50%);

    @include breakpoint(medium down) {
      display: block;
    }

    @include breakpoint(small only) {
      height: rem-calc(45);
    }
  }

  img {
    width: auto;
    height: rem-calc(32);

    @include breakpoint(medium) {
      height: rem-calc(44);
    }
  }

  @include breakpoint(small only) {
    @include xy-cell(shrink, $gutters: 20px, $gutter-type: 'padding');
    margin-left: 0;
  }

  @include breakpoint(medium only) {
    @include xy-cell(shrink, $gutters: 40px, $gutter-type: 'padding');
  }

  @include breakpoint(large) {
    position: absolute;
    left: 0;
    bottom: rem-calc(45);
    margin-left: 0;
    transform: translateY(50%);

    &::after {
      display: none;
    }
  }
}

.Header-menuButton {
  display: block;

  @include breakpoint(large) {
    display: none;
  }
}

.Header-menu {
  @include xy-cell(auto);

  @include breakpoint(large) {
    @include xy-cell(auto);
  }
}

.Header-search {
  @include xy-cell(12);

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: 0);
  }
}

.Header-pro {
  position: absolute;
  line-height: 1.3;
  transition: visibility 0s linear;

  @include breakpoint(medium down) {
    @include xy-cell(shrink, $gutters: 40px, $gutter-type: 'padding');
    left: 0;
    bottom: 0;
  }

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: 30px, $gutter-type: 'padding');
    top: rem-calc(45);
    right: 0;
    transform: translateY(-50%);
  }
}

.Header-proLogin {
  @include link-underline;
  font-size: rem-calc(15);
  font-weight: 300;

  @include breakpoint(medium down) {
    display: inline-flex;
    align-items: center;
    height: rem-calc(80);
  }
}

.Header-social {
  @include xy-cell(shrink, $gutters: 30px);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: rem-calc(80);

  @include breakpoint(large) {
    display: none;
  }
}

body.nav-open {
  .Header-top {
    @include breakpoint(medium down) {
      box-shadow: rem-calc(0 1 5 0) $shadow-soft;
    }
  }
}

body.header-reduced {
  @include breakpoint(large) {
    .Header {
      transform: translateY(rem-calc(-90));
    }

    .Header-logoRF,
    .Header-logoSPF,
    .Header-pro {
      visibility: hidden;
      transition: visibility 0s linear .4s;
    }
  }
}
