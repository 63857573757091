/* TISEDownload component */

.TISEDownload {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
  }
}

.TISEDownload-container {
  @extend %container;
  text-align: center;
}

.TISEDownload-title {
  display: inline-block;
  max-width: rem-calc(900);
  margin-bottom: rem-calc(65);

  span {
    background-image: linear-gradient(to right, $color-white, $color-white);
    background-repeat: no-repeat;
    background-size: 100% 65%;
    background-position: center bottom 15%;
  }
}
