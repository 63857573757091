/*! Hero component */

.Hero {
  position: relative;
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(50);
  background-color: $color-gray;
  overflow: hidden;

  @include breakpoint(medium) {
    padding-top: rem-calc(70);
    padding-bottom: rem-calc(80);
  }
}

.Hero-button {
  margin-top: rem-calc(40);
}

.Hero--image {
  &:not(.Hero--imageDesktop) {
    @include breakpoint(small only) {
      padding-bottom: rem-calc(200);
    }

    @include breakpoint(medium only) {
      padding-bottom: rem-calc(170);
    }
  }
}

.Hero--imageDesktop {
  @include breakpoint(medium down) {
    .Hero-img {
      display: none;
    }
  }
}

.Hero--waves {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  background-size: auto 200%;

  @include breakpoint(medium) {
    background-size: 150% auto;
  }

  // specific style for IE11
  body.explorer & {
    background-size: 500% auto;

    @include breakpoint(medium) {
      background-size: 500% auto;
    }
  }
}

.Hero--blue {
  background-color: map-get($color-brand, 'blue');
  color: $color-white;

  .Hero-toolbarLink {
    background-color: map-get($color-brand, 'red');
  }
}

.Hero-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: rem-calc(240);
  text-align: center;
  transform: translateX(-50%);

  @include breakpoint(medium) {
    left: initial;
    right: 0;
    height: rem-calc(200);
    text-align: right;
    transform: translateX(0);
  }

  @include breakpoint(large) {
    position: absolute;
    top: 0;
    bottom: initial;
    left: 0;
    right: initial;
    width: 100%;
    height: 100%;
    text-align: initial;
  }

  img {
    width: auto;
    max-width: initial;
    height: 100%;
    object-fit: contain;
    object-position: center right;

    @include breakpoint(large) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: initial;
    }
  }
}

.Hero-container {
  @extend %container;
}

.Hero-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  z-index: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.Hero-breadcrumbs,
.Hero-title,
.Hero-introduction,
.Hero-dates,
.Hero-toolbar {
  @include xy-cell(12);

  @include breakpoint(large) {
    @include xy-cell(8.5);
  }
}

.Hero-breadcrumbs {
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(20);
  }
}

.Hero-title {
  text-align: center;
}

.Hero-introduction {
  margin-top: rem-calc(20);
  font-weight: 300;
  text-align: center;

  @include breakpoint(small only) {
    font-size: rem-calc(16);
  }
}

.Hero-dates {
  margin-top: rem-calc(20);
  font-size: rem-calc(14);
  font-weight: 300;
  text-align: center;

  @include breakpoint(medium) {
    font-size: rem-calc(16);
    font-weight: initial;
  }
}

.Hero-updateDate {
  &::before {
    content: ' - ';
  }
}

.Hero-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem-calc(30);
}

.Hero-toolbarItem {
  margin-left: rem-calc(7);
  margin-right: rem-calc(7);
}

.Hero-toolbarLink--facebook {
  @include sprite('facebook', (fill: $color-white));
  background-size: rem-calc(10);
}

.Hero-toolbarLink--twitter {
  @include sprite('twitter', (fill: $color-white));
  background-size: rem-calc(18);
}

.Hero-toolbarLink--mail {
  @include sprite('mail', (fill: $color-white));
  background-size: rem-calc(20);
}

.Hero-toolbarLink--download {
  @include sprite('download', (fill: $color-white));
  background-size: rem-calc(16);
}

.Hero-toolbarLink {
  display: block;
  width: rem-calc(30);
  height: rem-calc(30);
  border-radius: rem-calc(50%);
  background-color: map-get($color-brand, 'blue');
  transition: transform .65s ease(out-quint);

  &:hover,
  #{$keyboard} &:focus {
    outline: none;
    transform: scale(1.2);
  }
}
