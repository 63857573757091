/*! Global Form style */

// Reset & Global style
input,
select,
textarea {
  display: inline-block;
  width: 100%;
  height: rem-calc(50);
	margin: 0;
  line-height: 1;
  border: none;
  border-radius: rem-calc(4);
	vertical-align: middle;
	white-space: normal;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

// Form specific style
.Form {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(100);
  background-color: $color-white;

  input,
  select,
  textarea {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: rem-calc(25);
    padding-right: rem-calc(25);
    font-size: rem-calc(16);
    font-weight: 300;
    border-radius: rem-calc(8);
    background-color: $color-white;
    box-shadow: inset rem-calc(0 0 0 1) $color-divider;
    transition: box-shadow 0.4s ease(out-quint);

    &:focus {
      box-shadow: inset rem-calc(0 0 0 1) map-get($color-brand, 'blue');
      outline: none;
    }
  }

  input,
  textarea {
    &:required:valid {
      background-color: $color-white;
      box-shadow: inset rem-calc(0 0 0 1) map-get($color-brand, 'blue');
    }
  }

  input:required:valid {
    @include sprite('check', (fill: map-get($color-brand, 'blue')));
    padding-right: rem-calc(65);
    background-size: rem-calc(15) auto;
    background-position: center right rem-calc(25);

    @include breakpoint(large) {
      padding-right: rem-calc(80);
      background-position: center right rem-calc(40);
    }
  }

  textarea {
    height: rem-calc(250);
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    line-height: initial;
  }

  select,
  input[data-input-type="date"] {
    @include sprite('arrow-down', (fill: map-get($color-brand, 'blue')));
    padding-right: rem-calc(65);
    background-color: $color-white;
    background-size: rem-calc(15 30);
    background-position: center right rem-calc(25);

    @include breakpoint(large) {
      padding-right: rem-calc(80);
      background-position: center right rem-calc(40);
    }

    body.explorer & {
      &::-ms-expand {
        display: none;
      }
    }
  }

  input[type="date"] {
    line-height: rem-calc(50);
  }
}

.Form-container {
  @extend %container;
}

.Form-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  justify-content: center;
}

.Form-grid {
  @include xy-cell(12, $gutter-type: none);
  @include xy-grid;

  @include breakpoint(large) {
    @include xy-cell(8, $gutter-type: none);
  }
}

.Form-item {
  @include xy-cell(12);
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    @include xy-cell(12);
    margin-bottom: rem-calc(50);

    &.Form-item--half {
      @include xy-cell(6);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(12, $gutters: rem-calc(30));

    &.Form-item--half {
      @include xy-cell(6, $gutters: rem-calc(30));
    }
  }

  & > label {
    display: inline-block;
    margin-bottom: rem-calc(10);
    font-size: rem-calc(20);
    font-weight: 700;

    &.required {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: rem-calc(5);
        font-size: rem-calc(12);
        transform: translateY(-8px);
      }
    }

    & + .Form-sublabel {
      margin-top: rem-calc(-10);
      margin-bottom: rem-calc(10);
    }
  }

  & > ul {
    margin-top: rem-calc(5);
    font-size: rem-calc(12);
    font-weight: 500;
    color: map-get($color-brand, 'red');
  }

  textarea + textarea {
    margin-top: rem-calc(30);
  }
}

.Form-sublabel {
  display: block;
  font-weight: 300;
}

.Form-item--error {
  input,
  select,
  textarea {
    box-shadow: inset rem-calc(0 0 0 1) map-get($color-brand, 'red');
  }
}

.Form-item--legend {
  @include breakpoint(medium) {
    margin-top: rem-calc(-35);
  }

  p {
    font-weight: 300;

    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }
  }
}

.Form-item--submit {
  text-align: center;

  @include breakpoint(small only) {
    .Button {
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    margin-top: rem-calc(30);
  }
}

.Form-itemInfo {
  font-size: rem-calc(14);
  font-weight: 500;
  color: rgba($color-black, 0.6);
}

.Form-subtitle {
  margin-bottom: rem-calc(20);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  p {
    font-size: rem-calc(22);
    font-weight: 700;
  }
}

// Custom checkbox style
.Form-checkbox {
  & > div {
    &:not(:last-child) {
      margin-bottom: rem-calc(15);
    }
  }

  input {
    @extend .sr-only;

    &:checked + label {
      color: map-get($color-brand, 'blue');

      &::before {
        background-color: map-get($color-brand, 'blue');
        transition: background-color .4s ease(out-quint);
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    #{$keyboard} &:focus + label {
      color: map-get($color-brand, 'blue');
    }
  }

  label,
  label.required {
    position: relative;
    padding-left: rem-calc(40);
    font-size: rem-calc(16);
    font-weight: 300;
    transition: color .3s ease(out-quint);

    &::before {
      content: '';
      position: absolute;
      top: rem-calc(-3);
      left: 0;
      width: rem-calc(24);
      min-width: rem-calc(24);
      height: rem-calc(24);
      min-height: rem-calc(24);
      background-color: $color-white;
      border-radius: rem-calc(7);
      box-shadow: inset rem-calc(0 0 0 2) map-get($color-brand, 'blue');
      transition: background-color .3s ease(out-quint);
    }

    &::after {
      content: '';
      @include sprite('check', (fill: $color-white));
      position: absolute;
      top: rem-calc(-3);
      left: 0;
      width: rem-calc(24);
      min-width: rem-calc(24);
      height: rem-calc(24);
      min-height: rem-calc(24);
      margin-left: 0;
      background-size: auto 55%;
      transform: scale(0);
      opacity: 0;
      transition: opacity .3s ease(out-quint), transform .25s ease(out-back);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// Custom radio style
.Form-radio {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem-calc(-15);

  & > div {
    margin-bottom: rem-calc(10);

    &:not(:last-child) {
      margin-right: rem-calc(30);

      @include breakpoint(large) {
        margin-right: rem-calc(40);
      }
    }
  }

  input {
    @extend .sr-only;

    &:checked + label {
      color: map-get($color-brand, 'blue');

      &::before {
        box-shadow: inset rem-calc(0 0 0 9) map-get($color-brand, 'blue');
        transition: box-shadow .55s ease(out-quint);
      }
    }

    #{$keyboard} &:focus + label {
      color: map-get($color-brand, 'blue');
    }
  }

  label {
    position: relative;
    padding-left: rem-calc(40);
    font-size: rem-calc(16);
    font-weight: 300;
    transition: color .4s ease(out-quint);

    &::before {
      content: '';
      position: absolute;
      top: rem-calc(-3);
      left: 0;
      width: rem-calc(24);
      min-width: rem-calc(24);
      height: rem-calc(24);
      min-height: rem-calc(24);
      background-color: $color-white;
      border-radius: rem-calc(12);
      box-shadow: inset rem-calc(0 0 0 2) map-get($color-brand, 'blue');
      transition: box-shadow .4s ease(out-quint);
    }

    &:hover {
      cursor: pointer;
    }
  }

  & + ul {
    margin-top: rem-calc(20);
  }
}

.flatpickr-calendar {
  width: initial;
  padding: rem-calc(10);

  @include breakpoint(medium) {
    padding: rem-calc(20 30);
  }

  .flatpickr-weekday {
    font-size: rem-calc(14);
    font-weight: 300;
  }
}

.flatpickr-months {
  margin-bottom: rem-calc(10);

  .flatpickr-current-month {
    .flatpickr-monthDropdown-months,
    .numInput {
      color: map-get($color-brand, 'blue');
      font-weight: 700;

      option {
        color: $color-black;
        font-weight: 400;
      }
    }
  }

  .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-next-month.flatpickr-next-month {
    top: rem-calc(10);

    @include breakpoint(medium) {
      top: rem-calc(20);
    }

    svg {
      fill: map-get($color-brand, 'blue');
    }
  }

  .flatpickr-prev-month.flatpickr-prev-month {
    left: rem-calc(30);
  }
  .flatpickr-next-month.flatpickr-next-month {
    right: rem-calc(30);
  }
}

.flatpickr-day {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $color-gray;
    border-color: $color-gray;
  }

  &.today {
    border-color: $color-divider;
  }

  &.selected,
  &.selected.nextMonthDay,
  &.selected:hover {
    color: $color-black;
    background: none;
    border-color: map-get($color-brand, 'blue');
    border-width: rem-calc(2);
  }

  &.nextMonthDay,
  &.prevMonthDay {
    color: $color-divider;
    font-weight: 300;
  }

  &.flatpickr-disabled {
    color: rgba($color-divider, .3);
  }
}
