/*! Test page specific style */

body.test_form {
  .CtaBlock {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
    background-color: $color-white;
  }

  .CtaBlock-text {
    font-size: rem-calc(22);
  }

  .Form-radio {
    display: block;
  }

  .Form-item--legend {
    margin-top: 0;
  }
}
