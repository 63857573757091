/*! Callback Component */

.Callback {
  input,
  select {
    width: 100%;
    height: rem-calc(50);
    margin-top: rem-calc(50);
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    font-size: rem-calc(35);
    font-weight: 700;
    color: map-get($color-brand, 'blue');
    border-radius: 0;
    background-color: transparent;
    box-shadow: inset rem-calc(1 0 0 0) map-get($color-brand, 'blue');
    transition: box-shadow 0.4s ease(out-quint);

    @include breakpoint(medium) {
      width: calc(100% - #{rem-calc(100)});
      height: rem-calc(60);
      margin-top: rem-calc(35);
      padding-left: rem-calc(25);
      padding-right: rem-calc(25);
      font-size: rem-calc(42);
    }

    &:focus {
      box-shadow: inset rem-calc(2 0 0 0) map-get($color-brand, 'blue');
      outline: none;
    }

    &::placeholder {
      color: rgba(map-get($color-brand, 'blue'), .4);
      text-transform: uppercase;
    }
  }

  select option {
    font-size: initial;
    color: initial;
  }

  .grecaptcha-badge {
    transform: translateY(#{rem-calc(100)});

    @include breakpoint(medium) {
      transform: translateY(#{rem-calc(150)});
    }

    @include breakpoint(xlarge) {
      bottom: 0 !important;
      transform: translateY(0);
    }
  }
}

.Callback-container {
  @extend %container;
}

.Callback-content {
  position: relative;
  padding-top: rem-calc(130);
  padding-bottom: rem-calc(100);

  @include breakpoint(medium) {
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(150);
  }
}

.Callback-title {
  margin-bottom: rem-calc(75);
  color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
    @include xy-cell-offset(1, $gutters: 0);
  }
}

.Callback-errors {
  margin-bottom: rem-calc(20);

  p,
  ul {
    font-size: rem-calc(16);
    font-weight: 500;
    color: map-get($color-brand, 'red');

    @include breakpoint(large) {
      @include xy-cell(10, $gutter-type: none);
      @include xy-cell-offset(1, $gutters: 0);
    }
  }
}

.Callback-form {
  @include breakpoint(large down) {
    overflow: visible;
  }
}

.Callback-slide {
  @include xy-grid;
}

.Callback-item {
  position: relative;
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
    @include xy-cell-offset(1, $gutters: 0);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8, $gutter-type: none);
  }

  select {
    @include sprite('arrow-down', (fill: map-get($color-brand, 'blue')));
    background-size: rem-calc(20) auto;
    background-position: center left rem-calc(215);
  }

  label,
  .sublabel {
    display: block;
    font-size: rem-calc(20);
    font-weight: 300;
    color: map-get($color-brand, 'blue');
  }

  .sublabel {
    opacity: .5;
  }

  &--error {
    input,
    input:focus,
    select,
    select:focus {
      box-shadow: inset rem-calc(2 0 0 0) map-get($color-brand, 'red');
      background-color: rgba(map-get($color-brand, 'red'), .05);
    }
  }
}

.Callback-previous {
  position: absolute;
  top: rem-calc(75);
  display: inline-flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition:
  visibility 0s linear .4s,
  opacity .4s ease(out-quint);

  @include breakpoint(medium) {
    top: rem-calc(75);
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      outline: none;

      &::before {
        transform: translateX(-5px);
      }
    }
  }

  &--is-visible {
    opacity: 1;
    visibility: visible;
    transition:
      visibility 0s linear 0s,
      opacity .4s ease(out-quint);
  }

  &::before {
    content: '';
    @include sprite('arrow-left');
    display: inline-block;
    width: rem-calc(10);
    height: rem-calc(17);
    margin-right: rem-calc(15);
    background-size: contain;
    transition: transform .65s ease(out-quint);
  }
}

.Callback-next,
.Callback-submit {
  @include sprite('waves', (fill: rgba($color-white, .2)));
  width: rem-calc(80);
  height: rem-calc(50);
  margin-top: rem-calc(70);
  color: $color-white;
  border-radius: rem-calc(20);
  background-size: rem-calc(600) auto;
  background-position: top 48% center;
  transition: background-position .65s ease(out-quint), background-color .4s ease(out-quint), box-shadow .25s ease(out-quint);
  background-color: map-get($color-brand, 'blue');

  @include breakpoint(large) {
    &:not([disabled]) {
      &:hover {
        background-position: top 52% center;
        background-color: darken(map-get($color-brand, 'blue'), 8);
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: rgba(map-get($color-brand, 'blue'), .7);
    }
  }

  #{$keyboard} &:focus {
    outline: none;
    box-shadow: inset rem-calc(0 0 0 3) map-get($color-brand, 'yellow');
  }

  @include breakpoint(medium) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: rem-calc(100);
    height: rem-calc(60);
    margin-top: 0;
    border-radius: rem-calc(20);
  }
}
