/*! FAQ Table Component */

.FaqTable {
  position: relative;
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(60);
  background-color: $color-lightgray;

  &::after {
    content: '';
    @include sprite('waves', (fill: rgba($color-white, .05)));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: map-get($color-brand, 'blue');
    background-size: auto 300%;

    @include breakpoint(large) {
      background-size: 150% auto;
    }
  }

  // specific style for IE11
  body.explorer & {
    &::after {
      background-size: 400% auto;

      @include breakpoint(medium) {
        background-size: 400% auto;
      }
    }
  }
}

.FaqTable-container {
  @extend %container;
  margin-bottom: rem-calc(35);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(60);
  }
}

.FaqTable-content {
  @include xy-grid;
  z-index: 2;
  position: relative;
  justify-content: center;
  background-color: $color-white;
  border-radius: rem-calc(30);
  overflow: hidden;

  @include breakpoint(medium) {
    border-radius: rem-calc(45);
  }
}

.FaqTable-head {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  @include xy-cell(12, $gutter-type: none);
  @include xy-grid;
  justify-content: center;
  padding-top: rem-calc(20);
  background-color: map-get($color-brand, 'red');
  background-size: 150% auto;

  @include breakpoint(medium down) {
    display: none;
  }

  .FaqTable-row {
    @include xy-cell(10, $gutter-type: none);
    font-size: rem-calc(22);
    font-weight: 700;
    color: $color-white;
  }
}

.FaqTable-list {
  @include xy-cell(11);
  @include xy-grid;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);

  @include breakpoint(medium) {
    @include xy-cell(9, $gutter-type: none);
  }

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(35);
  }

  .FaqTable-date,
  .FaqTable-theme {
    @include breakpoint(large) {
      font-weight: 300;
    }
  }

  .FaqTable-date {
    @include breakpoint(small only) {
      font-size: rem-calc(13);
    }

    @include breakpoint(medium down) {
      font-weight: 300;
    }
  }

  .FaqTable-theme {
    @include breakpoint(small only) {
      font-size: rem-calc(15);
    }

    @include breakpoint(medium down) {
      font-weight: 500;
    }
  }
}

.FaqTable-row {
  @include xy-cell(12, $gutter-type: none);
  @include xy-grid;
  @include sprite('arrow-right');
  position: relative;
  align-items: center;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);
  padding-right: rem-calc(30);
  line-height: 1.2;
  background-size: rem-calc(10 17);
  background-position: bottom rem-calc(17) right;

  &:not(:first-child) {
    box-shadow: rem-calc(0 -1 0 0) $shadow-soft;
  }

  @include breakpoint(medium) {
    padding-right: rem-calc(34);
    background-size: rem-calc(14 22);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(25);
    padding-right: 0;
    background: none;
  }
}

.FaqTable-date,
.FaqTable-theme {
  @include breakpoint(large) {
    order: 1;
  }
}

.FaqTable-title {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(4.5, $gutter-type: none);
    order: 2;
  }
}

.FaqTable-titleLink {
  color: map-get($color-brand, 'blue');
  text-decoration: underline;
  transition: text-decoration-color .4s ease(out-quint);

  @include breakpoint(medium down) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} {
      text-decoration-color: transparent;
    }
  }
}

.FaqTable-date {
  @include xy-cell(12, $gutter-type: none);
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);

  @include breakpoint(large) {
    @include xy-cell(3, $gutter-type: none);
    order: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.FaqTable-theme {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(4.5, $gutters: rem-calc(40));
  }
}
