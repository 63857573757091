/*! Social component */

.Social-list {
  display: flex;
}

.Social-link {
  position: relative;
  display: block;
  width: rem-calc(40);
  height: rem-calc(40);

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      &::after {
        background-color: $color-black;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem-calc(30);
    height: rem-calc(30);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background-color .55s ease(out-quint);
  }
}

.Social-facebook {
  &::after {
    @include sprite('facebook', (fill: $color-white));
  }
}

.Social-twitter {
  &::after {
    @include sprite('twitter', (fill: $color-white));
  }
}

.Social-facebook,
.Social-twitter {
  &:after {
    background-color: map-get($color-brand, 'blue');
    background-size: rem-calc(16 16);
  }
}
