/*! InternalMesh Component */

.revert-color {
  .InternalMesh {
    @include sprite('waves', (fill: rgba($color-white, .05)));
    background-size: auto 200%;
    background-color: map-get($color-brand, 'blue');

    @include breakpoint(large) {
      background-size: 150% auto;
    }

    body.explorer & {
      background-size: 500% auto;

      @include breakpoint(medium) {
        background-size: 500% auto;
      }
    }

    .InternalMesh-title,
    .InternalMesh-introduction {
      color: $color-white;
    }

    &:nth-child(even) {
      background-color: $color-lightgray;

      .InternalMesh-title,
      .InternalMesh-introduction {
        color: initial;
      }
    }
  }
}

.InternalMesh {
  padding-top: rem-calc(55);
  padding-bottom: rem-calc(35);
  background-color: $color-lightgray;

  @include breakpoint(medium) {
    padding-top: rem-calc(75);
  }

  &:nth-child(even) {
    @include sprite('waves', (fill: rgba($color-white, .05)));
    background-size: auto 200%;
    background-color: map-get($color-brand, 'blue');

    @include breakpoint(large) {
      background-size: 150% auto;
    }

    body.explorer & {
      background-size: 500% auto;

      @include breakpoint(medium) {
        background-size: 500% auto;
      }
    }

    .InternalMesh-title,
    .InternalMesh-introduction {
      color: $color-white;
    }
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(-70);
    padding-bottom: 0;
  }

  & + .InternalMesh {
    padding-top: rem-calc(115);
  }

  &.InternalMesh--centered {
    .InternalMesh-title,
    .InternalMesh-introduction {
      text-align: center;
    }

    .InternalMesh-introduction {
      margin-left: auto;
      margin-right: auto;
    }

    &:not(.InternalMesh--centeredSlider) {
      .InternalMesh-list {
        @include breakpoint(large) {
          justify-content: space-around;
        }
      }
    }
  }
}

.InternalMesh-container {
  @extend %container
}

.InternalMesh-title {
  margin-bottom: rem-calc(15);

  @include breakpoint(large) {
    margin-bottom: rem-calc(30);
  }
}

.InternalMesh-introduction {
  margin-bottom: rem-calc(20);
  font-size: rem-calc(16);
  font-weight: 300;

  @include breakpoint(medium) {
    width: 75%;
    margin-bottom: rem-calc(40);
    font-size: initial;
  }
}

.InternalMesh-links {
  position: relative;
}

.InternalMesh-slider {
  @include breakpoint(medium down) {
    @include xy-gutters($negative: true);
    overflow: visible;
  }

  @include breakpoint(xxlarge) {
    @include xy-gutters($negative: true);
  }
}

.InternalMesh-sliderPrevious {
  @include sprite('arrow-left', (fill: $color-white));
  left: rem-calc(-10);
  background-position: left 48% center;

  @include breakpoint(xxlarge) {
    left: rem-calc(-25);
  }
}

.InternalMesh-sliderNext {
  @include sprite('arrow-right', (fill: $color-white));
  right: rem-calc(-10);
  background-position: right 48% center;

  @include breakpoint(xxlarge) {
    right: rem-calc(-25);
  }
}

.InternalMesh-sliderPrevious,
.InternalMesh-sliderNext {
  z-index: 1;
  top: calc(50% + #{rem-calc(15)});
  width: rem-calc(40);
  height: rem-calc(40);
  border-radius: 50%;
  background-color: $color-black;
  background-size: rem-calc(8 14);
  transform: translateY(-50%);
  transition: opacity .4s ease(out-quart);

  @include breakpoint(medium down) {
    display: none;
  }

  &::after {
    display: none;
  }

  &.swiper-button-disabled {
    pointer-events: initial;
  }

  body.explorer & {
    transform: translateY(-100%);
  }
}

.InternalMesh-list {
  padding-bottom: rem-calc(15);
}

.InternalMesh-item {
  @include xy-cell(10);
  background-color: $color-white;
  border-radius: rem-calc(20);
  box-shadow: rem-calc(0 4 10 0) $shadow-soft;

  @include breakpoint(medium) {
    @include xy-cell(5);
  }

  @include breakpoint(large) {
    @include xy-cell(3);
  }
}

.InternalMesh-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: rem-calc(180);
  padding: rem-calc(20 25 30);

  @include breakpoint(large) {
    &:hover ,
    #{$keyboard} &:focus {
      .InternalMesh-linkLabel {
        outline: none;
        transform: translateX(20px);
        transition: transform .8s ease(out-quint);

        &::before,
        &::after {
          transition: opacity .45s ease(out-quint), transform .45s ease(out-quint);
        }

        &::before {
          transform: translate(0, -50%);
          opacity: 1;
        }

        &::after {
          transform: translate(5px, -50%);
          opacity: 0;
        }
      }
    }
  }
}

.InternalMesh-linkTitle {
  font-size: rem-calc(20);
  font-weight: 700;
  line-height: 1.2;
}

.InternalMesh-linkLabel {
  align-self: flex-start;
  position: relative;
  font-size: rem-calc(16);
  font-weight: 700;
  color: map-get($color-brand, 'blue');
  transition: transform .55s ease(out-quint);

  &::before,
  &::after {
    @include sprite('arrow-right', (fill: map-get($color-brand, 'blue')));
    content: '';
    position: absolute;
    top: 50%;
    width: rem-calc(8);
    height: rem-calc(13);
    background-size: contain;
    transform: translateY(-50%);
    transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);
  }

  &::before {
    right: calc(100% + #{rem-calc(12)});
    transform: translate(-5px, -50%);
    opacity: 0;
  }

  &::after {
    left: calc(100% + #{rem-calc(12)});
    transform: translate(0, -50%);
    opacity: 1;
  }
}
