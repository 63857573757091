/* TISEPartners component */

.TISEPartners {
  padding-bottom: rem-calc(50);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(110);
  }
}

.TISEPartners-back {
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(50);
}

.TISEPartners-backButton {
  display: inline-flex;
  align-items: center;
  font-weight: 700;

  &::before {
    content: '';
    @include sprite('arrow-left');
    display: inline-block;
    width: rem-calc(14);
    height: rem-calc(14);
    margin-right: rem-calc(10);
    transition: transform .25s ease(out-quint);
  }

  @include breakpoint(large) {
    &:hover,
    #{keyboard} &:focus {
      &::before {
        transform: translateX(-5px);
        transition: transform .8s ease(out-quint);
      }
    }
  }
}

.TISEPartners-header {
  @include sprite('waves', (fill: rgba($color-white, .05)));
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(130);
  background-size: 150% auto;
  background-color: map-get($color-brand, 'blue');
}

.TISEPartners-content {
  margin-top: rem-calc(-70)
}

.TISEPartners-container {
  @extend %container;
}

.TISEPartners-title {
  color: $color-white;
  text-align: center;
}

.TISEPartners-list {
  margin-bottom: rem-calc(35);
  justify-content: center;

  @include breakpoint(medium) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(15));
  }
}

.TISEPartners-partner {
  margin-bottom: rem-calc(15);
  padding: rem-calc(20 25 30);
  text-align: center;
  background-color: $color-white;
  border-radius: rem-calc(20);
  box-shadow: rem-calc(0 4 10 0) $shadow-soft;

  @include breakpoint(medium) {
    @include xy-cell(6, $gutters: rem-calc(15));
  }

  @include breakpoint(large) {
    @include xy-cell(3, $gutters: rem-calc(15));
  }

  span {
    display: block;
    margin-top: rem-calc(10);
  }
}

.TISEPartners-partnerImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem-calc(120);

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: rem-calc(120);
  }
}

.TISEPartners-links {
  text-align: center;

  .Button {
    & + .Button {
      @include breakpoint(small only) {
        display: block;
        margin-top: rem-calc(20);
      }

      @include breakpoint(medium) {
        margin-left: rem-calc(50);
      }
    }
  }
}
