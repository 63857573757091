/*! Map results component */

.MapResults {
  .MapForm {
    @include breakpoint(medium) {
      @include xy-grid;
      align-items: center;

      input,
      select {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
      }
    }

    @include breakpoint(large) {
      @include xy-gutters($negative: true, $gutters: rem-calc(50));
    }
  }

  .MapForm-items {
    @include breakpoint(medium) {
      @include xy-grid;
      @include xy-cell(12, $gutters: 0);
      border-radius: rem-calc(20);
      box-shadow: rem-calc(0 0 0 2) map-get($color-brand, 'blue');
      background-color: $color-white;
    }

    @include breakpoint(large) {
      @include xy-cell(auto, $gutters: rem-calc(50));
    }
  }

  .MapForm-item {
    @include breakpoint(medium) {
      margin-top: 0;
      margin-bottom: 0;

      &:first-child {
        border-right: solid 1px $color-divider;
        @include xy-cell(auto, $gutter-type: none);
      }
    }
  }

  .MapForm-submit {
    text-align: center;

    @include breakpoint(medium) {
      @include xy-cell(shrink, $gutters: 0);
      text-align: initial;
    }

    @include breakpoint(large) {
      @include xy-cell(shrink, $gutters: rem-calc(50));
      margin-top: 0;
    }
  }

  .Pagination {
    position: initial;
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    background-color: $color-white;

    @include breakpoint(large) {
      padding-top: rem-calc(45);
      padding-bottom: rem-calc(45);
    }
  }

  .Pagination-content {
    @include breakpoint(large) {
      @include xy-cell(6, $gutter-type: none);
    }

    @include breakpoint(large only) {
      max-width: calc(50% - #{rem-calc(70)});
    }
  }
}

.MapResults--is-hidden {
  display: none;
}

.MapResults-header {
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);

  @include breakpoint(large) {
    background-color: $color-white;
  }
}

.MapResults-headerContainer {
  @extend %container;
}

.MapResults-title {
  margin-bottom: rem-calc(30);
  color: map-get($color-brand, 'blue');
}

.MapResults-results {
  position: relative;
  display: block;
  width: 100%;

  @include breakpoint(large) {
    min-height: calc(100vh - #{rem-calc(180)});
  }
}

.MapResults-filters {
  padding-top: rem-calc(35);
  background-color: $color-lightgray;

  .Controls {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(20);
  }

  .Controls-content {
    @include breakpoint(large) {
      @include xy-cell(6, $gutter-type: none);
    }

    @include breakpoint(large only) {
      max-width: calc(50% - #{rem-calc(70)});
    }
  }
}

.MapResults-filtersTitle {
  @extend %container;
  color: map-get($color-brand, 'blue');

  h2 {
    @include breakpoint(large) {
      @include xy-cell(6, $gutter-type: none);
      padding-right: rem-calc(15);
    }

    @include breakpoint(large only) {
      max-width: calc(50% - #{rem-calc(70)});
    }
  }
}

.MapResults-inscription {
  margin-bottom: rem-calc(30);
}

.MapResults-inscriptionContainer {
  @extend %container;
}

.MapResults-inscriptionContent {
  &.RichText {
    background-color: transparent;
  }

  @include breakpoint(large) {
    @include xy-cell(6, $gutter-type: none);
    padding-right: rem-calc(15);
  }

  @include breakpoint(large only) {
    max-width: calc(50% - #{rem-calc(70)});
  }

  p {
    padding-top: rem-calc(20);
    border-top: 1px solid $color-divider;
  }
}

.MapResults-map {
  height: rem-calc(400);

  @include breakpoint(medium down) {
    top: 0 !important; // Fix sticky display on mobile devices
  }

  @include breakpoint(large) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - #{rem-calc(90)});

    body.explorer & {
      &.is-stuck {
        position: fixed !important;
      }
    }

    &.is-at-top {
      top: 0 !important;
    }
  }
}

.MapResults-mapContainer {
  @include breakpoint(large) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
  }

  @include breakpoint(large only) {
    width: calc(50% + #{rem-calc(70)})
  }
}

.MapResults-list {
  @include breakpoint(medium only) {
    @include xy-grid;
  }
}

.MapResults-result {
  background-color: $color-lightgray;
  transition: background-color .4s ease(out-quint);

  @include breakpoint(small only) {
    &:nth-child(odd) {
      background-color: $color-white;
    }
  }

  @include breakpoint(medium only) {
    @include xy-cell(6, $gutter-type: none);

    &:nth-child(4n),
    &:nth-child(4n-1) {
      background-color: $color-white;
    }
  }

  @include breakpoint(large) {
    &:nth-child(odd) {
      background-color: $color-white;
    }

    &:hover {
      cursor: pointer;
      background-color: darken($color-lightgray, 5);
    }
  }

  &--is-selected,
  &--is-selected:hover {
    background-color: map-get($color-brand, 'blue') !important;

    .MapResult-infos,
    .MapResult-phone {
      color: $color-white;
    }
  }
}

.MapResult-container {
  @extend %container;
}

.MapResult-content {
  position: relative;
  @include xy-grid;
  @include xy-gutters($negative: true);
  align-items: center;
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);

  @include breakpoint(large) {
    width: calc(50% - #{rem-calc(35)});
    @include xy-gutters($negative: true, $gutters: rem-calc(50));
  }

  @include breakpoint(xlarge) {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

.MapResult-index {
  @include sprite('pinpoint', (fill: map-get($color-brand, 'red')));
  @include xy-cell(shrink);
  position: absolute;
  top: rem-calc(30);
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: rem-calc(35);
  height: rem-calc(45);
  font-weight: 700;
  color: $color-white;
  background-position: center bottom;

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: rem-calc(50));
    margin-left: 0;
  }

  @include breakpoint(xlarge) {
    position: static;
    min-width: rem-calc(40);
    height: rem-calc(50);
    font-size: rem-calc(20);
  }
}

.MapResult-infos {
  @include xy-cell(auto);
  transition: color .4s ease(out-quint);

  @include breakpoint(large) {
    @include xy-cell(auto, $gutters: rem-calc(50));
  }

  @include breakpoint(xlarge) {
    max-width: rem-calc(300);
  }
}

.MapResult-name {
  display: inline-block;
  font-weight: 700;
  line-height: 1.2;

  .MapInfoWindow & {
    font-size: rem-calc(16);
    margin-bottom: rem-calc(10);
  }
}

.MapResult-structureType {
  display: block;
  margin-bottom: rem-calc(10);
  font-size: rem-calc(16);
  font-weight: 500;
}

.MapResult-address {
  line-height: 1.2;
  font-style: initial;
  font-weight: 300;

  .MapInfoWindow & {
    font-size: rem-calc(15);
  }
}

.MapResult-address1,
.MapResult-address2  {
  display: block;
}

.MapResult-phone {
  @include xy-cell(shrink);
  align-self: flex-end;
  font-weight: 700;
  line-height: 1.2;
  color: map-get($color-brand, 'blue');
  transition: color .4s ease(out-quint);

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: rem-calc(50));
    font-size: rem-calc(20);
  }

  a {
    @include link-underline();
  }
}

.MapResults-noResults {
  padding-top: rem-calc(30);

  @include breakpoint(medium) {
    padding-top: rem-calc(50);
  }

  .RichText {
    background-color: $color-lightgray;
  }
}

.MapResults-noResultsContainer {
  @extend %container;
}

.MapResults-noResultsContent {
  @include breakpoint(large) {
    width: calc(50% - #{rem-calc(70)});
    padding-right: rem-calc(15);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6, $gutter-type: none);
  }
}
