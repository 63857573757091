/*! Map Form component */

.MapForm {
  input,
  select {
    @include sprite('search', (fill: $color-divider));
    height: rem-calc(50);
    padding-left: rem-calc(55);
    padding-right: rem-calc(25);
    font-size: rem-calc(16);
    border-radius: rem-calc(16);
    box-shadow: inset rem-calc(0 0 0 2) map-get($color-brand, 'blue');
    background-size: rem-calc(16 18);
    background-position: rem-calc(20) center;
    background-color: $color-white;
    transition: box-shadow 0.4s ease(out-quint);

    @include breakpoint(large) {
      height: rem-calc(60);
      padding-left: rem-calc(75);
      padding-right: rem-calc(25);
      border-radius: rem-calc(20);
      background-position: rem-calc(30) center;
    }

    &:focus {
      @include sprite('search', (fill: map-get($color-brand, 'blue')));
      background-size: rem-calc(16 18);
      background-position: rem-calc(20) center;
      background-color: $color-white;
      outline: none;

      @include breakpoint(large) {
        background-position: rem-calc(30) center;
      }
    }

    body.explorer & {
      &::-ms-expand {
        display: none;
      }
    }
  }
}

.MapForm-item {
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);
}

.MapForm-item--select {
  position: relative;

  select {
    padding-right: rem-calc(55);

    @include breakpoint(large) {
      padding-right: rem-calc(75);
    }
  }

  .icon {
    z-index: 1;
    position: absolute;
    top: 50%;
    right: rem-calc(20);
    width: rem-calc(18);
    transform: translateY(-50%);
    fill: map-get($color-brand, 'blue');

    @include breakpoint(large) {
      right: rem-calc(30);
    }
  }
}

.MapForm-submit {
  margin-top: rem-calc(30);
}
