/*! FAQ Accordion component */

.FaqAccordion {
  @include sprite('waves', (fill: rgba($color-white, .1)));
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(70);
  background-color: $color-gray;
  background-size: auto 200%;


  @include breakpoint(medium) {
    padding-top: rem-calc(90);
    padding-bottom: rem-calc(90);
    background-size: 150% auto;
    background-repeat: repeat-y;
  }
}

.FaqAccordion-content {
  @extend %container;
}

.FaqAccordion-title {
  text-align: center;
  margin-bottom: rem-calc(40);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(50);
  }
}

.FaqAccordion-list {
  @include xy-grid;
  justify-content: center;
}

.FaqAccordion-item {
  @include xy-cell(12, $gutter-type: none);
  background-color: $color-white;
  border-radius: rem-calc(8);
  box-shadow: rem-calc(0 3 8 2) $shadow-soft;

  @include breakpoint(medium) {
    @include xy-cell(10, $gutter-type: none);

    &:not(:first-child) {
      margin-top: rem-calc(35);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(8, $gutter-type: none);
  }

  &:not(:first-child) {
    margin-top: rem-calc(20);
  }

  &.is-invisible {
    display: none;
  }
}

.FaqAccordion-itemTitle {
  position: relative;
  display: block;
  padding: rem-calc(25 55 25 20);
  font-size: rem-calc(16);
  font-weight: 500;

  @include breakpoint(medium) {
    padding: rem-calc(30 72 30 40);
    font-size: rem-calc(20);
    font-weight: initial;
  }

  .is-active & {
    &::after {
      @include sprite('arrow-down', (fill: map-get($color-brand, 'blue')));
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  &::after {
    content: '';
    @include sprite('arrow-down');
    position: absolute;
    top: 50%;
    right: rem-calc(20);
    width: rem-calc(15);
    height: rem-calc(15);
    background-size: contain;
    transform: translateY(-50%);
    transition: transform .6s ease(out-quint), background 0s linear .075s;

    @include breakpoint(medium) {
      right: rem-calc(40);
      width: rem-calc(12);
      height: rem-calc(12);
    }
  }
}

.FaqAccordion-itemContent {
  @include accordion-content;
  padding: rem-calc(10 20 30 20);
  font-size: rem-calc(16);
  color: rgba($color-black, .5);

  @include breakpoint(medium) {
    padding: rem-calc(10 40 50 40);
    font-size: rem-calc(17);
  }
}

.FaqAccordion-more {
  margin-top: rem-calc(50);
  text-align: center;

  .Button {
    width: 100%;
  }

  @include breakpoint(medium) {
    margin-top: rem-calc(80);

    .Button {
      width: initial;
    }
  }
}

