/*! CardLink Component */

.Card {
  position: relative;
  height: 100%;

  @include breakpoint(large) {
    &:hover ,
    #{keyboard} &:focus,
    &.focus-visible {
      .Card-titleText {
        outline: none;
        transform: translateX(25px);
        transition: transform .8s ease(out-quint);

        &::before,
        &::after {
          transition: opacity .45s ease(out-quint), transform .45s ease(out-quint);
        }

        &::before {
          transform: translate(0, -50%);
          opacity: 1;
        }

        &::after {
          transform: translate(5px, -50%);
          opacity: 0;
        }
      }
    }
  }
}

.Card-image {
  position: relative;
  border-radius: rem-calc(15);
  height: 0;
  padding-top: 50%;
  background-color: $color-gray;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Card-title {
  margin-top: rem-calc(10);
  line-height: 1;
}

.Card-titleText {
  position: relative;
  display: inline-block;
  transform: translateX(0);
  transition: transform .55s ease(out-quint);

  &::before,
  &::after {
    @include sprite('arrow-right');
    content: '';
    position: absolute;
    top: 50%;
    width: rem-calc(10);
    height: rem-calc(17);
    background-size: contain;
    transform: translateY(-50%);
    transition: opacity .25s ease(out-quint), transform .25s ease(out-quint);
  }

  &::before {
    right: calc(100% + #{rem-calc(15)});
    transform: translate(-5px, -50%);
    opacity: 0;
  }

  &::after {
    left: calc(100% + #{rem-calc(15)});
    transform: translate(0, -50%);
    opacity: 1;
  }
}

.Card-description {
  margin-top: rem-calc(5);
  font-size: rem-calc(14);
}

.Card-link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
