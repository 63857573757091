.Timeline {
  position: relative;
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-gray;
  transition: background-color .65s ease(out-quint);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(70);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(120);
  }

  &--lighten {
    background-color: $color-lightgray;
  }
}

.TimelineAnimation {
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: rem-calc(195);
  width: 100%;
  max-width: rem-calc(1000);

  @include breakpoint(small only) {
    max-width: rem-calc(400);
    transform: translateX(-50%);
  }

  @include breakpoint(medium only) {
    left: initial;
    right: 10%;
    bottom: rem-calc(225);
    width: 48%;
    min-width: rem-calc(400);
  }

  @include breakpoint(large) {
    left: 40%;
    bottom: 0;
    width: 60%;
  }

  @include breakpoint(xlarge) {
    left: 50%;
    width: 50%;
  }
}

.TimelineAnimation-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .6s ease(out-quint) .3s;

  &.invisible {
    opacity: 0;
    transition: opacity 0s linear;
  }
}

.TimelineAnimation-video {
  display: block;
  width: 100%;
  height: auto;
  background-color: $color-white;
  visibility: hidden;
  transition: opacity .6s ease(out-quint) .3s;

  &.invisible {
    opacity: 0;
    transition: opacity 0s linear;
  }
}

.Timeline-container {
  @extend %container;
  @include xy-grid;
}

.Timeline-content {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(medium) {
    @include xy-cell(8, $gutter-type: none);
  }

  @include breakpoint(large) {
    @include xy-cell(6, $gutter-type: none);
  }
}

.Timeline-title {
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(50);
  }
}

.Timeline-slider {
  overflow: visible;
}

.Timeline-slide {
  height: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.swiper-slide-active {
    .Timeline-slideText .word {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform .8s ease(out-quart) calc(.4s + var(--line-index) * .1s),
        opacity .8s ease(out-quart) calc(.4s + var(--line-index) * .1s)
    }
  }

  &:not(.swiper-slide-active) {
    opacity: 0 !important;
  }

  &:nth-child(3n+2) {
    .Timeline-duration {
      color: map-get($color-brand, 'red');
    }
  }

  &:nth-child(3n+3) {
    .Timeline-duration {
      color: map-get($color-brand, 'blue');
    }
  }

  &:last-child {
    @include breakpoint(small only) {
      .Button {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.Timeline-slideText {
  .word {
    display: inline-block;
    transform: translateY(5px);
    opacity: 0;
    transition:
      transform 0s linear .6s,
      opacity 0s linear .6s;
  }
}

.Timeline-duration {
  font-size: rem-calc(30);
  font-weight: 700;
  line-height: 1.1;
  color: map-get($color-brand, 'yellow');

  @include breakpoint(medium) {
    font-size: rem-calc(40);
  }
}

.Timeline-subtitle {
  margin-bottom: rem-calc(5);
  font-weight: 300;

  @include breakpoint(medium) {
    font-size: rem-calc(22);
  }
}

.Timeline-description {
  margin-bottom: calc(#{rem-calc(310)} + 35%);
  font-size: rem-calc(20);
  font-weight: 700;
  line-height: 1.3;

  @include breakpoint(medium) {
    max-width: rem-calc(500);
    margin-bottom: rem-calc(440);
    font-size: rem-calc(25);
    line-height: initial;
  }

  @include breakpoint(large) {
    max-width: rem-calc(450);
    margin-bottom: rem-calc(185);
  }

  @include breakpoint(xlarge) {
    margin-bottom: rem-calc(250);
  }

  &.swiper-slide-active {
    .Timeline-text .word {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.Timeline-pagination {
  bottom: rem-calc(75) !important;
  text-align: initial;

  @include breakpoint(small only) {
    padding-left: rem-calc(13);
  }

  @include breakpoint(medium) {
    bottom: rem-calc(100) !important;
  }

  @include breakpoint(xlarge) {
    bottom: rem-calc(140) !important;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: rem-calc(12);
    width: rem-calc(10000);
    height: rem-calc(1);
    background-color: lighten($color-divider, 15);
    transform: translateX(-50%);

    @include breakpoint(medium) {
      bottom: rem-calc(6);
    }
  }

  .Timeline-paginationItem {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    font-weight: 500;
    color: lighten($color-divider, 15);
    background: none;
    border-radius: 0;
    opacity: 1;
    outline: none;
    transition: color .6s ease(out-quint);

    @include breakpoint(small only) {
      position: relative;
      align-items: center;
      justify-content: center;
      width: rem-calc(25);
      height: rem-calc(25);
    }

    &:hover {
      outline: none;
      color: $color-divider;

      &::after {
        background-color: $color-divider;
      }
    }

    #{$keyboard} &:focus {
      outline: none;
      color: $color-black;

      &::after {
        background-color: $color-black;
      }
    }

    &.swiper-pagination-bullet-active,
    #{$keyboard} &.swiper-pagination-bullet-active:focus {
      color: map-get($color-brand, 'yellow');

      &::after {
        background-color: map-get($color-brand, 'yellow');
      }

      @include breakpoint(small only) {
        .Timeline-paginationLabel {
          opacity: 1;
        }
      }
    }

    &:nth-child(3n+2) {
      &.swiper-pagination-bullet-active,
      #{$keyboard} &.swiper-pagination-bullet-active:focus {
        color: map-get($color-brand, 'red');

        &::after {
          background-color: map-get($color-brand, 'red');
        }
      }
    }

    &:nth-child(3n+3) {
      &.swiper-pagination-bullet-active,
      #{$keyboard} &.swiper-pagination-bullet-active:focus {
        color: map-get($color-brand, 'blue');

        &::after {
          background-color: map-get($color-brand, 'blue');
        }
      }
    }

    &.swiper-pagination-bullet {
      margin: 0;
    }

    &:not(:last-child) {
      margin-right: rem-calc(25);

      @include breakpoint(xlarge) {
        margin-right: rem-calc(50);
      }
    }

    &::after {
      content: '';
      display: inline-block;
      width: rem-calc(13);
      height: rem-calc(13);
      background-color: lighten($color-divider, 15);
      border-radius: 50%;
      transition: background-color .6s ease(out-quint);

      @include breakpoint(medium) {
        margin-top: rem-calc(5);
      }
    }
  }

  .Timeline-paginationLabel {
    @include breakpoint(small only) {
      position: absolute;
      bottom: rem-calc(25);
      white-space: nowrap;
      opacity: 0;
      transition: opacity .4s ease(out-quint);
    }
  }
}

// Specific style for firefox mobile
body.firefox {
  &.mobile,
  &.tablet {
    .Timeline,
    .Timeline--lighten {
      background-color: $color-white;
    }

    .TimelineAnimation-video {
      visibility: visible;
      background-color: $color-white;
    }

    .TimelineAnimation-canvas {
      display: none;
    }

    .Timeline-container {
      position: relative;
      z-index: 3;
    }
  }
}
