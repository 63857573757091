/*! Button component */

.Button {
  display: inline-block;
  padding-top: rem-calc(14);
  padding-bottom: rem-calc(14);
  font-size: rem-calc(17);
  font-weight: 700;
  text-align: center;
  line-height: 1;
  color: $color-black;

  &:not(.Button--alt) {
    min-width: rem-calc(200);
    padding-left: rem-calc(35);
    padding-right: rem-calc(35);
    background-color: $color-white;
    border-radius: rem-calc(17);
    transition: background-color .4s ease(out-quint), box-shadow .25s ease(out-quint);

    @include breakpoint(large) {
      &:hover {
        background-color: darken($color-white, 8);
      }

      #{$keyboard} &:focus {
        outline: none;
        box-shadow: inset rem-calc(0 0 0 3) map-get($color-brand, 'yellow');
      }
    }
  }
}

@each $color, $value in $color-brand {
  .Button--#{$color} {
    &:not(.Button--alt) {
      color: $color-white;
      background-color: map-get($color-brand, $color);

      @include breakpoint(large) {
        &:hover,
        #{$keyboard} &:focus {
          background-color: darken(map-get($color-brand, $color), 8);
        }
      }
    }
  }
}

.Button--waves {
  @each $color, $value in $color-brand {
    &.Button--#{$color} {
      @include sprite('waves', (fill: rgba($color-white, .2)));
      color: $color-white;
      background-size: rem-calc(600 510);
      background-position: top 48% center;
      transition: background-position .65s ease(out-quint), background-color .4s ease(out-quint), box-shadow .25s ease(out-quint);
      background-color: map-get($color-brand, $color);

      @include breakpoint(large) {
        &:hover {
          background-position: top 52% center;
          background-color: darken(map-get($color-brand, $color), 8);
        }
      }
    }
  }
}

.Button--alt {
  @include sprite('arrow-right');
  padding-right: rem-calc(25);
  background-size: rem-calc(8 16);
  background-position: center right;

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      outline: none;

      .Button-text {
        background-size: 100% rem-calc(1);
      }
    }
  }

  .Button-text {
    background-image: linear-gradient(to right, currentColor, currentColor);
    background-repeat: no-repeat;
    background-size: 0 rem-calc(1);
    background-position: left bottom;
    transition: background-size 0.8s ease(out-quint);
  }
}

.Button[disabled] {
  cursor: not-allowed;
  opacity: .4;
}
