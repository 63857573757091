/* TISEVideo component */


.TISEVideo {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-white;
}

.TISEVideo-container {
  @extend %container;

  @include breakpoint(small only) {
    .RichText-video {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
