/*! Categories Component */

.Categories {
  @include breakpoint(large) {
    background-image: linear-gradient(to right, $color-gray 50%, $color-lightgray 50%);
  }
}

.Categories-container {
  @include xy-grid-container($padding: 0);

  @include breakpoint(large) {
    @include xy-grid-container($width: rem-calc(1720), $padding: 0);
  }
}

.Categories-entries {
  @include xy-grid;
}

.Categories-entry {
  @include xy-cell(12, $gutter-type: none);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: $color-gray;
  padding: rem-calc(30);

  @include breakpoint(medium) {
    @include xy-cell(6, $gutter-type: none);
  }

  @include breakpoint(large) {
    padding: rem-calc(100 30);
  }

  &:last-child {
    background-color: $color-lightgray;

    .Categories-title {
      color: map-get($color-brand, 'blue');
    }
  }
}

.Categories-title {
  max-width: rem-calc(500);
  margin-bottom: rem-calc(20);
  text-align: center;
  color: map-get($color-brand, 'red');

  @include breakpoint(medium down) {
    font-size: rem-calc(35);
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(80);
  }
}

.Categories-img {
  max-width: rem-calc(550);
}

.Categories-img--anim {
  display: none;
}

.Categories-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      & + .Categories-img--static {
        display: none;

        & + .Categories-img--anim {
          display: inline-block;
        }
      }
    }
  }
}
