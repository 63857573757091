/* TISEEmployer component */

.TISEEngagement {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-lightgray;
}

.TISEEngagement-container {
  @extend %container;
}

.TISEEngagement-content {
  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(100));
  }
}

.TISEEngagement-title {
  .RichText & {
    margin-bottom: rem-calc(60);
  }
}

.TISEEngagement-text,
.TISEEngagement-images {
  @include breakpoint(large) {
    @include xy-cell(6, $gutters: rem-calc(100));
  }
}

.TISEEngagement-text {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(50);
  }

  .nb {
    display: inline-block;
    font-size: rem-calc(14);

    a {
      font-weight: 300;
    }
  }
}

.TISEEngagement-images {
  @include breakpoint(large) {
    transform: translateY(rem-calc(-60));
  }

  img + img {
    margin-top: rem-calc(75);
  }
}
