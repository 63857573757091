/*! Init fonts */

$font-boing: "../fonts/boing/";

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Thin.woff2') format('woff2'),
      url('#{$font-boing}Boing-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Light.woff2') format('woff2'),
      url('#{$font-boing}Boing-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Regular.woff2') format('woff2'),
      url('#{$font-boing}Boing-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Medium.woff2') format('woff2'),
      url('#{$font-boing}Boing-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Semibold.woff2') format('woff2'),
      url('#{$font-boing}Boing-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Boing';
  src: url('#{$font-boing}Boing-Bold.woff2') format('woff2'),
      url('#{$font-boing}Boing-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
