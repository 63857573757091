/*! Triptych component */

.Triptych {
  &--3-columns {
    .Triptych-column:nth-child(2) {
      background-color: map-get($color-brand, 'blue');
      color: $color-white;
    }
  }

  .RichText {
    background-color: transparent;
  }
}

.Triptych-container {
  @extend %container;

  @include breakpoint(medium down) {
    padding-left: 0;
    padding-right: 0;
  }
}

.Triptych-content {
  @include xy-grid;

  @include breakpoint(large) {
    @include xy-gutters($negative: true, $gutters: rem-calc(90));
  }
}

.Triptych-column {
  @include xy-cell(12, $gutter-type: none);
  padding: rem-calc(30 20);

  @include breakpoint(medium) {
    padding: rem-calc(45);
  }

  @include breakpoint(large) {
    @include xy-cell(auto, $gutter-type: none);
    padding: rem-calc(100 45);
  }
}

