/*! Terms Container */

.Terms {
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(50);
  background-color: $color-lightgray;
}

.Terms-container {
  @extend %container;
}

.Terms-title {
  margin-bottom: rem-calc(20);
}

.Terms-dropdown {
  text-align: center;
}

.Terms-dropdownContent {
  position: relative;
  height: rem-calc(80);
  font-size: rem-calc(14);
  font-weight: 500;
  text-align: initial;
  overflow-y: hidden;
  transition: max-height .6s ease(out-quint);

  &::after {
    content: '';
    @include scrimGradient('to top', $color-lightgray);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150%;
    transform: scaleY(1);
    transform-origin: center bottom;
    transition: transform .6s ease(out-quint);
  }

  &.is-open {
    height: auto;

    &::after {
      transform: scaleY(0);
    }
  }
}

.Terms-dropdownButton {
  @include link-underline(true);
  margin-top: rem-calc(15);
  font-size: rem-calc(16);
  font-weight: 700;
  color: map-get($color-brand, 'blue');

  &::after {
    content: attr(aria-label);
  }
}
