/*! TISEHeader component */

.TISEHeader {
  background-color: $color-white;

  .icon-logo-republique-francaise {
    width: rem-calc(51);
    height: rem-calc(45);

    @include breakpoint(medium) {
      width: rem-calc(68);
      height: rem-calc(60);
    }
  }

  .icon-logo-sante-publique {
    width: rem-calc(67);
    height: rem-calc(38);

    @include breakpoint(medium) {
      width: rem-calc(88);
      height: rem-calc(50);
    }
  }

  .icon-logo-tis {
    width: rem-calc(56);
    height: rem-calc(30);

    @include breakpoint(medium) {
      width: rem-calc(82);
      height: rem-calc(44);
    }
  }
}

.TISEHeader-container {
  @extend %container;
}

.TISEHeader-nav {
  @include xy-grid;
  @include xy-gutters($negative: true, $gutters: rem-calc(30));

  @include breakpoint(medium) {
    @include xy-gutters($negative: true, $gutters: rem-calc(50));
  }
}

.TISEHeader-logo {
  @include xy-cell(shrink, $gutters: rem-calc(30));
  display: flex;
  align-items: center;
  height: rem-calc(100);
  min-height: rem-calc(100);

  @include breakpoint(small only) {
    &:last-child {
      position: relative;
      padding-left: rem-calc(30);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 50%;
        background-color: $color-gray;
        transform: translateY(-50%);
      }
    }
  }

  @include breakpoint(medium) {
    @include xy-cell(shrink, $gutters: rem-calc(50));

    &:last-child {
      margin-left: rem-calc(50);
    }
  }
}
