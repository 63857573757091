/* TISENews component */

.TISENews {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-lightgray;

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
  }
}

.TISENews-container {
  @extend %container;
}

.TISENews-content {
  @include breakpoint(medium down) {
    position: relative;
    padding-bottom: rem-calc(100);
  }

  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(100));
    align-items: center;
  }
}

.TISENews-text {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(50);
  }

  @include breakpoint(large) {
    @include xy-cell(4.5, $gutters: rem-calc(100));
  }

  h2 {
    margin-top: 0;
    color: map-get($color-brand, 'blue');
  }

  .Button {
    @include breakpoint(small only) {
      width: 100%;
    }

    @include breakpoint(medium down) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(35);
    }
  }
}

.TISENews-image {
  @include breakpoint(large) {
    @include xy-cell(7.5, $gutters: rem-calc(100));
  }
}
