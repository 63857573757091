/*! SearchInput component */

.SearchInput {
  .SearchAutocomplete {
    @include xy-cell(12, $gutter-type: none);

    @include breakpoint(medium) {
      @include xy-cell(11, $gutter-type: none);
    }

    @include breakpoint(large) {
      @include xy-cell(8, $gutter-type: none);
    }
  }
}

.SearchInput-container {
  @extend %container;
  @include xy-grid;
  position: relative;
  justify-content: center;
  padding-top: rem-calc(80);
  padding-bottom: rem-calc(50);

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
  }
}

.SearchInput-form {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(medium) {
    @include xy-cell(11, $gutter-type: none);
  }

  @include breakpoint(large) {
    @include xy-cell(8, $gutter-type: none);
  }

  & > ul {
    margin-top: rem-calc(5);
    font-size: rem-calc(12);
    font-weight: 500;
    color: map-get($color-brand, 'red');

    @include breakpoint(medium) {
      margin-left: rem-calc(20);
    }
  }
}

.SearchInput-content {
  @include xy-grid;
  flex-wrap: nowrap;
  border-radius: rem-calc(30);
  overflow: hidden;

  @include breakpoint(medium) {
    border-radius: rem-calc(20);
  }
}

.SearchInput-input {
  @include sprite('search', (fill: $color-divider));
  @include xy-cell(auto, $gutter-type: none);
  height: rem-calc(55);
  padding-left: rem-calc(60);
  padding-right: rem-calc(20);
  font-size: rem-calc(16);
  font-weight: 300;
  background-color: $color-white;
  background-size: rem-calc(16 18);
  background-position: rem-calc(25) center;

  @include breakpoint(medium) {
    height: rem-calc(75);
    padding-left: rem-calc(100);
    padding-right: rem-calc(30);
    font-size: rem-calc(18);
    background-position: rem-calc(50) center;
  }

  &:focus {
    outline: none;
  }
}

.SearchInput-valid {
  @include sprite('waves', (fill: rgba($color-white, .2)));
  @include xy-cell(shrink, $gutter-type: none);
  width: rem-calc(60);
  min-width: rem-calc(60);
  color: $color-white;
  border-top-right-radius: rem-calc(20);
  border-bottom-right-radius: rem-calc(20);
  background-size: rem-calc(600) auto;
  background-position: top 48% center;
  transition: background-position .65s ease(out-quint), background-color .4s ease(out-quint), box-shadow .25s ease(out-quint);
  background-color: map-get($color-brand, 'blue');

  @include breakpoint(medium) {
    width: rem-calc(80);
  }

  @include breakpoint(large) {
    &:hover {
      background-position: top 52% center;
      background-color: darken(map-get($color-brand, 'blue'), 8);
    }

    #{$keyboard} &:focus {
      outline: none;
      box-shadow: inset rem-calc(0 0 0 3) map-get($color-brand, 'yellow');
    }
  }
}
