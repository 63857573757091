/*! Breadcrumbs Component */
.Breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Breadcrumbs-item {
  font-weight: 500;

  &:not(:last-child) {
    &::after {
      content: '>';
      display: inline-block;
      margin-left: .5ch;
      margin-right: .5ch;
    }
  }

  @include breakpoint(small only) {
    font-size: rem-calc(14);
  }
}

.Breadcrumbs-link {
  @include link-underline;
}
