/*! SearchResults Component */

.SearchResults {
  @include breakpoint(medium) {
    padding-top: rem-calc(50);
  }

  .Controls {
    padding-top: rem-calc(60);
  }

  .Pagination {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
    background-color: $color-white;
  }
}

.SearchResults-container,
.SearchResult-container {
  @extend %container;
}

.SearchResults-resultsNumber {
  color: map-get($color-brand, 'blue');
}

.SearchResult {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: $color-white;

  &:nth-child(even) {
    background-color: $color-lightgray;
  }
}

.SearchResult--accordion {
  .SearchResult-container {
    &.is-active {
      .SearchResult-link {
        &::after {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .SearchResult-link {
    &::after {
      @include sprite('arrow-down');
      content: '';
      display: inline-block;
      width: rem-calc(18);
      height: rem-calc(13);
      margin-left: rem-calc(15);
      transition: transform .6s ease(out-quint);
    }
  }

  .SearchResult-preview {
    @include accordion-content;
    padding-top: rem-calc(15);
  }
}

.SearchResult-link {
  display: inline-block;
}

.SearchResult-title {
  @include link-underline($inverted: true);
  color: map-get($color-brand, 'blue');
}

.SearchResult-preview {
  padding-top: rem-calc(15);
}
