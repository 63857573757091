/*! Question Block Component */

.QuestionBlock-question,
.QuestionBlock-answer {
  padding-top: rem-calc(35);
  padding-bottom: rem-calc(35);

  @include breakpoint(medium) {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(60);
  }
}

.QuestionBlock-question {
  background-color: $color-white;
}

.QuestionBlock-back {
  padding-bottom: rem-calc(35);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(60);
  }
}

.QuestionBlock-container {
  @extend %container;
}

.QuestionBlock-title {
  margin-bottom: rem-calc(30);
  padding-bottom: rem-calc(35);
  color: map-get($color-brand, 'blue');
  box-shadow: rem-calc(0 1 0 0) $shadow-soft;
}

.QuestionBlock-questionName {
  margin-bottom: rem-calc(20);
}

.QuestionBlock-text {
  font-weight: 300;
}

.QuestionBlock-backButton {
  display: inline-flex;
  align-items: center;
  font-weight: 700;

  &::before {
    content: '';
    @include sprite('arrow-left');
    display: inline-block;
    width: rem-calc(14);
    height: rem-calc(14);
    margin-right: rem-calc(10);
    transition: transform .25s ease(out-quint);
  }

  @include breakpoint(large) {
    &:hover,
    #{keyboard} &:focus {
      &::before {
        transform: translateX(-5px);
        transition: transform .8s ease(out-quint);
      }
    }
  }
}
