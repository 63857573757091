/*! Testimonials Component */

.Testimonials {
  position: relative;
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);
  background-color: $color-lightgray;

  &::after {
    content: '';
    @include sprite('waves', (fill: rgba($color-white, .05)));
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: map-get($color-brand, 'blue');
    background-size: auto 200%;
    background-repeat: repeat-y;

    @include breakpoint(medium) {
      top: 20%;
      height: 60%;
      background-size: 150% auto;
    }
  }

  body.explorer & {
    &::after {
      background: none;
      background-color: map-get($color-brand, 'blue');
    }
  }

  @include breakpoint(medium) {
    padding-bottom: rem-calc(50);
  }
}

.Testimonials-container {
  @extend %container;
  margin-bottom: rem-calc(50);
}

.Testimonials-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  align-items: flex-start;
}

.Testimonials-item {
  @include xy-cell(12);
  z-index: 2;
  margin-bottom: rem-calc(15);

  body.explorer & {
    display: flex;
  }

  @include breakpoint(large) {
    height: auto;

    &:nth-child(odd) {
      @include xy-cell(8);
    }

    &:nth-child(even) {
      @include xy-cell(4);
    }

    &:nth-child(3),
    &:nth-child(4) {
      @include xy-cell(6);
    }
  }

  .Testimonial-identity {
    color: map-get($color-brand, 'blue');
  }

  &:nth-child(3n+2) {
    .Testimonial-identity {
      color: map-get($color-brand, 'yellow');
    }
  }

  &:nth-child(3n+3) {
    .Testimonial-identity {
      color: map-get($color-brand, 'red');
    }
  }
}
